import React from 'react';
import socialLinks from '../../constants/social-links';
import './FAQS.scss';

function TermsEn() {
  return (
    <div className="terms-article-holder">
      <h5 className="top-heading">Welcome to Al Zoghbi!</h5>
      <p>
        Here you can find all the terms and conditions that we apply to perform
        our provided services at the highest level of our standards. If you’re
        here, that means you’re smart enough to read them carefully before using
        our services.
      </p>
      <h2>
        PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY. THEY CONTAIN
        IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND OBLIGATIONS, AS WELL AS
        LIMITATIONS AND EXCLUSIONS THAT APPLY TO YOUR PURCHASES.
      </h2>
      <h4>GENERAL SCOPE</h4>
      <p>
        Al Zoghbi Pharmacy offers a wide range of products, including medicine,
        cosmetics, medical devices, paramedicals, baby products, and personal
        care items. The company aims to serve all individuals, regardless of age
        or demographic.
      </p>
      <p>
        By using the Services, you automatically agree to the Terms of Service.
      </p>
      <h4>DATA COLLECTION, USE AND DELETION</h4>
      <h3>DATA COLLECTION</h3>
      <p>
        We collect the customer’s personal information including name, phone
        number, address, e-mail address, password and payment information in the
        case of paying using card.
      </p>
      <h3>DATA USE</h3>
      <p>
        Data collected are used for the purpose of providing the user with Al
        Zoghbi platform’s services, notify them with tailored offers and
        promotions through our email and for direct contact with the user.
      </p>
      <h3>DATA DELETION</h3>
      <p>
        If the user no longer desires to use our services, we can remove the
        personal information provided to us. The user can reach us out by
        contacting us on our hotline 16007 from within Egypt or sending an
        e-mail with your information to{' '}
        <a href={`mailto:${socialLinks.email}`}>{socialLinks.email}</a> , and
        specifying the information to be removed.
      </p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <h4>REGISTRATION and ORDERING PROCESS</h4>
      <h3>2.1. REGISTRATION</h3>
      <p>
        You are the sole authorized User of the account (Account) you create
        using the Services. Your action of registration constitutes your
        acceptance of the Terms of Service and the Privacy Policy. Registration
        is free of charge. No entitlement exists for admission to the Service.
        The data required for registration provided by the User must be complete
        and accurate. The User is responsible for updating his/her data that can
        be amended at any time from the respective interface of the Service. Al
        Zoghbi has no responsibility for the use of the User account and
        expressly disclaims any liability therefrom. Should you suspect that any
        unauthorized party might be using your Account, you are obligated for
        your security to notify Al Zoghbi immediately by emailing us at&nbsp;
        <span>{socialLinks.email}</span> By providing your mobile phone number
        to Al Zoghbi pursuant to the use of the Service, you hereby
        affirmatively consent to our use of your mobile phone number for
        contacting you directly in order to perform the Services, including but
        not limited to occasionally send notifications, text messages with
        promotional offers, service updates and reach out to you to survey
        regarding the quality of our services.
      </p>
      <h3>ORDER PLACEMENT / CONTRACTUAL RELATIONSHIP</h3>
      <p>
        By placing an order through the website, the User confirms the accuracy
        of all the information he/she provides. Orders are confirmed to the User
        within Service interface. Al Zoghbi Pharmacy may decide for any reason
        whatsoever to not accept an order and to refuse to perform it. User may
        schedule the order for a selected time, date, week or month as per
        availability of Service.
      </p>
      <h3>AMENDING AND CANCELLING ORDERS</h3>
      <p>
        The User may not be able to amend the order once it has been confirmed
        as per the technical features provided by the Service. A confirmed order
        is eligible for cancellation for a limited time period until it is
        disbursed for delivery post to its submission as per the technical
        specifications of the Service.
      </p>
      <h3>INCOMPLETE ORDER FULFILLMENT / SUBSTITUTION</h3>
      <p>
        The primary objective of Al Zoghbi is to deliver all the products
        ordered in the right quantity and to a high-quality standard. User
        acknowledges that the Pharmacies are subject to stock availability and
        to human errors. Al Zoghbi reserves the right to amend your order in
        whole or in part, at any time and without liability or compensation
        remove any item that is out of stock, damaged, spoiled, or unavailable
        for any other reason, to successfully complete your order. It’s in our
        best intention to maintain the replaced item’s price the same as per the
        brand you ordered but in case of a higher valued replacement, the price
        may increase. We do our best to ensure that all items shown on our
        website are available to order. If, however, any product you order is
        out of stock or unavailable we may send you a push notification enabling
        you to select a substitute. If an item is not delivered, despite being
        billed, the amount in question will be credited to the User at a
        reasonable time after Al Zoghbi becomes aware of this. No subsequent
        delivery is obligatory to be made, and the customer is not entitled to
        claim any further compensation. If for any reason beyond our reasonable
        control, we are unable to supply a particular item, we will not be
        liable to the User. Please note that we will attempt to send the User
        push notification of substitute products in the event that selected
        products are unavailable, the User may reject these substitutes.
        Although we will always try to cater for your orders, an order of
        unusually large quantities of different or one product can only be
        fulfilled at the discretion of the Pharmacy.
      </p>
      <h4>DELIVERY</h4>
      <h3>DELIVERY OF PRODUCTS</h3>
      <p>
        Al Zoghbi Pharmacy primarily delivers to Nasr City and New Cairo, with
        additional shipping options available throughout Egypt. Products will be
        delivered directly to the delivery address specified by the User.
      </p>
      <h3>DELIVERY TIMES AND ADHERENCE TO DELIVERY PERIODS</h3>
      <p>
        Al Zoghbi endeavors to deliver within 30 (Thirty) to 60 (Sixty) minutes
        average delivery time location dependent or as scheduled.
      </p>
      <h3>DELIVERY RECEIPT</h3>
      <p>
        Our pharmacy holds the responsibility to provide the user with the
        receipt upon delivery.
      </p>
      <h3>CANCELLATION OF A DELIVERY BY Al Zoghbi</h3>
      <p>
        If, for reasons beyond Al Zoghbi’ control - such as an incorrect
        delivery address, lack of an access permit, bad weather conditions, or
        similar, it should prove impossible or possible only with great
        difficulty, to carry-out the delivery successfully, Al Zoghbi is
        entitled to cancel the User’s order. In this event, the User is not
        entitled to the compensation, however; in case of online payment, the
        refund will be initiated by Al Zoghbi at the soonest possible post the
        cancellation of the order, it is the responsibility of the bank to
        transfer the amount to the user’s account, which might take up to 14
        working days.
      </p>
      <h4>PRICES AND PAYMENT</h4>
      <p>
        Al Zoghbi endeavors to provide you with accurate and up-to-date pricing,
        product availability and promotional information. Discrepancies are
        possible and you agree not to hold Al Zoghbi liable in such instances.
      </p>
      <h4>PRICES</h4>
      <p>
        All prices are quoted in the country’s local currency and the prices
        displayed on the website will be VAT inclusive. Al Zoghbi Pharmacy
        reserves the right to update product prices on the website as needed. In
        cases where the price displayed on the website has not been updated
        according to the new prices, Al Zoghbi Pharmacy retains the right to
        adjust the price accordingly. Customers will be informed of any price
        changes and have the option to continue or cancel their order based on
        the updated price.{' '}
      </p>
      <h4>PAYMENT METHODS / CREDITWORTHINESS</h4>
      <p>
        You can choose from the following ways of paying on delivery, depending
        on the products, the means of dispatch and as per technical
        availability:
      </p>
      <ul>
        <li>Credit card reader on delivery</li>
        <li>Online payment</li>
        <li>Cash on delivery</li>
      </ul>
      <p className="start-parg">
        * We accept payments online using Visa, MasterCard and AMEX (valid for
        EGYPT only) credit/debit card in EGP.
      </p>
      <p className="start-parg">
        * Any dispute or claim arising out of or in connection with this website
        shall be governed and construed in accordance with the laws of EGYPT.
      </p>
      <p className="start-parg">* Egypt is our country of domicile.</p>
      <p className="start-parg">
        * Minors under the age of 18 shall are prohibited to register as a User
        of this website and are not allowed to transact or use them
      </p>
      <p className="start-parg">
        * If you make a payment for our products on our website, the details you
        are asked to submit will be provided directly to our payment provider
        via a secured connection.
      </p>
      <p className="start-parg">
        * The cardholder must retain a copy of transaction records and Merchant
        policies and rules.{' '}
      </p>
      <p>
        The User can select which payment method is preferred. The payment
        methods available are displayed for selection when the order is
        finalized. If for any reason the online payment transaction is declined,
        Al Zoghbi is entitled to offer the User cash on delivery payment option
        or credit card reader on delivery where available.{' '}
      </p>
      <h4>RETURN</h4>
      <p>
        Al Zoghbi Pharmacy has a 14-days return policy for products that are not
        damaged. Customers must provide a receipt slip for the return to be
        processed. Refrigerated medicines, personal use items, and unsealed
        products are not eligible for returns.
      </p>
      <h3>PRODUCT DECLARATION</h3>
      <h4>PRODUCT INFORMATION</h4>
      <p>
        We take care to update the product information in the Service regularly.
        In rare cases, the information may deviate from the details printed on
        the product packaging. In such an instance, the information on the
        packaging takes precedence.{' '}
      </p>
      <h4>GUARANTEE of DATA COMMUNICATION</h4>
      <p>
        Given the current state of the technology, no guarantee can be given
        that data communication via the internet will be error-free and/or
        available at all times. Al Zoghbi, therefore, accepts no liability in
        respect of the constant, uninterrupted availability neither of the
        online shop nor for technical and electronic faults during sales
        transaction, in particular for any delay in processing or accepting
        orders.{' '}
      </p>
      <h4>CUSTOMER SERVICE &amp; COMPLAINTS</h4>
      <p>
        Al Zoghbi Customer Service provides information regarding any questions,
        issues or complaints related to the Al Zoghbi service. Users may contact
        Al Zoghbi via e-mail
        <a href={`mailto:${socialLinks.email}`}>{socialLinks.email}</a>, drop a
        message through the app or call the customer service line 15672. Latent
        defects must be reported to Al Zoghbi Customer Service immediately after
        they come to light, although in these cases there are limitations of
        error acknowledgment.
      </p>
      <h3>GENERAL TERMS</h3>
      <h4>6.1. DATA PROTECTION</h4>
      <p>
        Data collected are treated as confidential and in good faith. When
        registration is made, your data is collected for business and marketing
        purposes in the form of personal information such as last name, first
        name, address, email address and phone number. In addition to data
        explicitly entered, information is gathered automatically from the log
        files when you access the Services interface. Al Zoghbi makes a
        distinction between master data (e.g. IP address, time and date of
        access) and activity data (e.g. name of file accessed, paths clicked
        on). For statistical purposes, this data is anonymously assessed.{' '}
      </p>
      <h4>DISCLAIMER</h4>
      <p className="upper-parg">
        USE OF THE SUPPORT IS ENTIRELY AT YOUR OWN RISK. CHANGES ARE
        PERIODICALLY MADE TO THE SOFTWARE APPLICATIONS AND MAY BE MADE AT ANY
        TIME WITHOUT NOTICE TO YOU. THE SUPPORT IS PROVIDED ON AN "AS IS" BASIS
        WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
        BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT. Al Zoghbi MAKES NO WARRANTIES
        OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT
        PROVIDED THROUGH THE SUPPORT OR THE CONTENT OF ANY SOFTWARE APPLICATIONS
        LINKED TO THE ZOGHBI PHARMACY SOFTWARE APPLICATIONS. Al Zoghbi ASSUMES
        NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
        NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE Al
        Zoghbi SOFTWARE APPLICATIONS OR THE SUPPORT; (III) ANY UNAUTHORIZED
        ACCESS TO OR USE OF AL ZOGHBI PHARMACY’S SECURE SERVERS AND/OR ANY AND
        ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION THEREIN.
      </p>
      <p>
        AL ZOGHBI PHARMACY DOES NOT WARRANT THAT THE SOFTWARE APPLICATIONS WILL
        OPERATE ERROR-FREE OR THAT THE SOFTWARE APPLICATIONS AND ITS SERVER ARE
        FREE OF COMPUTER VIRUSES AND OTHER HARMFUL GOODS. IF YOUR USE OF THE
        SOFTWARE APPLICATIONS RESULTS IN THE NEED FOR SERVICING OR REPLACING
        EQUIPMENT OR DATA, Al Zoghbi SHALL NOT BE RESPONSIBLE FOR THOSE COSTS.
        Al Zoghbi, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL
        WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE
        WARRANTY OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTY RIGHTS AND
        THE WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE. Al Zoghbi MAKES NO
        WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS OR TIMELINESS
        OF THE CONTENT, SERVICES, SUPPORT, SOFTWARE, TEXT, GRAPHICS OR LINKS. Al
        Zoghbi AND LICENSORS CANNOT AND DO NOT GUARANTEE THAT ANY PERSONAL
        INFORMATION SUPPLIED BY YOU WILL NOT BE MISAPPROPRIATED, INTERCEPTED,
        DELETED, DESTROYED OR USED BY OTHERS.
      </p>
      <h4>LIMITATION OF LIABILITY</h4>
      <p className="upper-parg">
        YOU AGREE THAT Al Zoghbi SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF
        OR IN CONNECTION WITH (I) YOUR USE OF THE SUPPORT; (II) THE LIABILITY OR
        FITNESS OF ANY CUSTOMER (III) IN CONNECTION WITH THE PERFORMANCE OF OR
        BROWSING IN THE SOFTWARE APPLICATIONS OR YOUR LINKS TO OTHER SOFTWARE
        APPLICATIONS FROM THIS SOFTWARE APPLICATIONS, EVEN IF Al Zoghbi HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU FURTHER AGREE THAT Al
        Zoghbi SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING FROM INTERRUPTION,
        SUSPENSION OR TERMINATION OF SERVICES, INCLUDING WITHOUT LIMITATION ANY
        DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
        DAMAGES, WHETHER SUCH INTERRUPTION, SUSPENSION OR TERMINATION WAS
        JUSTIFIED OR NOT, NEGLIGENT OR INTENTIONAL, INADVERTENT OR ADVERTENT. IN
        NO EVENT SHALL AL ZOGHBI PHARMACY’S TOTAL LIABILITY TO YOU FOR ANY
        LOSSES ARISING HEREUNDER EXCEED THE AMOUNTS PAID BY YOU TO Al Zoghbi
        HEREUNDER. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION
        OF WARRANTIES OR OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO
        SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. SHOULD A
        JURISDICTION BE ADVERSE TO A LIMITATION OR EXCLUSION OF WARRANTIES, SUCH
        PROVISION SHALL BE DEEMED SEVERABLE FROM THIS AGREEMENT AND THE OTHER
        PROVISIONS SHALL REMAIN IN FULL FORCE AND EFFECT.
      </p>
      <h4>WAIVER</h4>
      <p>
        No waiver of any rights or remedies by Al Zoghbi shall be effective
        unless made in writing and signed by an authorized representative of Al
        Zoghbi. A failure by Al Zoghbi to exercise or enforce any rights
        conferred upon us by Terms of Service shall not be deemed to be a waiver
        or variation of any such rights or operate so as to bar the exercise or
        enforcement thereof at any subsequent time or times.{' '}
      </p>
      <h4>INDEMNITY</h4>
      <p>
        You agree to defend, indemnify and hold harmless Al Zoghbi Pharmacy and
        its officers, directors, employees, agents and affiliates (each, an
        Indemnified Party), from and against any losses, claims, actions, costs,
        damages, penalties, fines and expenses, including without limitation
        attorneys' fees and expenses, that may be incurred by an Indemnified
        Party arising out of, relating to or resulting from your unauthorized
        use of the Software Applications or from any breach by you of Terms of
        Service, including without limitation any actual or alleged violation of
        any federal, state or local statute, ordinance, administrative order,
        rule or regulation. Al Zoghbi shall provide notice to you promptly of
        any such claim, suit or proceeding and shall have the right to control
        the defense of such action, at your expense, in defending any such
        claim, suit or proceeding.
      </p>
      <h4>TERMINATION</h4>
      <p>
        At its sole discretion, Al Zoghbi may modify or discontinue service or
        may modify, suspend or terminate your access to Service or the Support,
        for any reason, with or without notice to you and liability to you or
        any third party. In addition to suspending or terminating your access to
        Service or the Support, Al Zoghbi reserves the right to take appropriate
        legal action, including without limitation pursuing civil, criminal or
        injunctive redress. Even after your right to use the Support is
        terminated, your Account will remain enforceable against you. You may
        terminate Account at any time. However, Al Zoghbi does not guarantee its
        termination due to technical availability. All provisions which by their
        nature should survive to give effect to those provisions shall survive
        the termination of Account.{' '}
      </p>
      <h4>GOVERNING LAW AND SEVERABILITY</h4>
      <p>
        This Agreement is governed by the laws of Egypt, the courts shall have
        exclusive jurisdiction and venue over any dispute arising out of or
        relating to this Agreement, and each party hereby consents to the
        jurisdiction and venue of such courts. Without regards to its conflict
        of laws principles. If any provision of this Agreement is found to be
        invalid in any court having competent jurisdiction, the invalidity of
        such provision shall not affect the validity of the remaining provisions
        of this Agreement, which shall remain in full force and effect. Any
        offer for any product, feature or application made on this Software
        Applications is void where prohibited.{' '}
      </p>
      <h4>CHANGES TO THE AGREEMENT</h4>
      <p>
        Al Zoghbi reserves the right, at its sole and absolute discretion, to
        change, modify, add to, supplement or delete any of the terms and
        conditions of this Agreement, effective with or without prior notice.
        Your continued use of the Software Applications or the Support following
        any revision to this Agreement constitutes your complete and irrevocable
        acceptance of any and all such changes. Contact us If you have any
        comments or questions, please do not hesitate to reach out to us at{' '}
        <a href={`mailto:${socialLinks.email}`}>{socialLinks.email}</a>
      </p>
      <h4>Contact Details</h4>
      <p>
        Egypt. | 65 Abbas Al Akkad St., Nasr City, Cairo | Hotline: 
        <a href={`tel:${socialLinks.hotline}`}>{socialLinks.hotline}</a> |
        <br />
        Email: <a href={`mailto:${socialLinks.email}`}>{socialLinks.email}</a>
      </p>
    </div>
  );
}

export default TermsEn;
