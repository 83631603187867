import { BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import Routes from './Routes';
import { ConfigProvider } from 'antd';
import React, { Component } from 'react';
// import './antd.css';
// import 'antd/dist/antd.css';
import './scss/antd/antd.css';
import './scss/pages/app.scss';
import { connect } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { setCurrentLang } from './redux/user/user.action';
import meta from './Localization/meta.json';
import * as Sentry from '@sentry/react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import FloatButton from './components/FloatButton/FloatButton';
// import { Helmet } from 'react-helmet';

const queryClient = new QueryClient();
class App extends Component {
  componentDidMount() {
    this.getLanguage();
    console.log('app.js', this.props);

    // if (this.props.location.pathname.includes('/en')) {
    if (localStorage.getItem('lang') === 'en') {
      localStorage.setItem('lang', 'en');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
      this.props.dispatch(setCurrentLang('en'));
    } else {
      localStorage.setItem('lang', 'ar');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
      this.props.dispatch(setCurrentLang('ar'));
    }

    // if (localStorage.getItem('lang') === null) {
    //   localStorage.setItem('lang', 'en');
    //   document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
    //   this.props.dispatch(setCurrentLang('en'));
    // } else {
    //   document
    //     .getElementsByTagName('html')[0]
    //     .setAttribute('lang', this.props.currentLang);
    // }

    var uniq = Date.now().toString(36) + Math.random().toString(36).substr(2);
    if (localStorage.getItem('deviceId') === null) {
      localStorage.setItem('deviceId', uniq);
    }
  }

  getLanguage = () => {
    if (localStorage.getItem('lang') === null) {
      localStorage.setItem('lang', 'en');
      this.props.history.replace('/en/');
      console.log('props', this.props);
      document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
    } else {
      document
        .getElementsByTagName('html')[0]
        .setAttribute('lang', this.props.currentLang);
    }
  };

  render() {
    const { currentLang } = this.props;
    return (
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <div className="app-wrapper">
            <ConfigProvider direction={currentLang === 'en' ? 'ltr' : 'rtl'}>
              <Helmet>
                <title>{meta.KhairZaman[currentLang]}</title>
                <meta
                  name="description"
                  content={meta.KhairZamandescription[currentLang]}
                />
                <script>{`gtag('event', 'conversion', {'send_to': 'AW-590585837/V2OUCOKei6EDEO2_zpkC'});`}</script>
              </Helmet>
              <Router>
                <Switch>
                  <Routes />
                </Switch>
              </Router>
            </ConfigProvider>
          </div>
          <FloatButton />
        </QueryClientProvider>
      </HelmetProvider>
    );
  }
}

const SentryApp = Sentry.withProfiler(App);
const AppWithRouter = withRouter(SentryApp);

const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(AppWithRouter);
