import { css } from '@emotion/css';
import { useSelector } from 'react-redux';
import waitIcon from './wait.svg';
// import { useTranslation } from 'react-i18next'
import localization from '../../Localization/Localization.json';

function ComingSoon() {
  const ComingSoonStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0;
    img {
      width: 8rem;
    }
    .title {
      font-family: 'arthead', sans-serif;
      font-size: 70px;
      line-height: 1.3;
      text-transform: capitalize;
    }
  `;
  // const { t } = useTranslation()
  const { currentLang } = useSelector(s => s.user);

  return (
    <div className={ComingSoonStyles}>
      <img src={waitIcon} alt="coming soon" />
      <div className="title ani-text-shine">
        {localization.coming_soon[currentLang]}
      </div>
    </div>
  );
}

export default ComingSoon;
