import { css } from '@emotion/css';
import ComingSoon from '../ComingSoon/ComingSoon';
import PrescriptionSend from '../PrescriptionSend/PrescriptionSend';

function UploadPrescription() {
  const UploadPrescriptionStyles = css``;

  return (
    <div className={UploadPrescriptionStyles}>
      {/* <ComingSoon /> */}
      <PrescriptionSend />
    </div>
  );
}

export default UploadPrescription;
