import { css } from '@emotion/css';
import ComingSoon from '../../components/ComingSoon/ComingSoon';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import PrescriptionSend from '../../components/PrescriptionSend/PrescriptionSend';

function ComingSoonPage() {
  const ComingSoonPageStyles = css`
    padding: 80px 0;
  `;

  return (
    <>
      <Header />
      <div className={ComingSoonPageStyles}>
        <ComingSoon />
        <PrescriptionSend />
      </div>
      <Footer />
    </>
  );
}

export default ComingSoonPage;
