import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import '../../scss/pages/auth.scss';
import { handelResetPassword } from '../../network/auth.network';
import { connect } from 'react-redux';
import { codeValidation, codeValidationAr } from '../../constants/constants';
import localization from '../../Localization/Localization.json';
// import logo from "../../assets/img/Logo.png"
import logo from '../../assets/img/logogreen-croped.png';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { setCurrentUser } from '../../redux/user/user.action';
import { clearCart } from '../../redux/cart/cart.action';
import { Link as I18Link } from 'react-router-i18n';
import { Helmet } from 'react-helmet-async';
import meta from '../../Localization/meta.json';

class ResetPassword extends Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      isLoginValid: false,
      disableResetCode: false,
      tel: null,
    };
  }

  componentDidMount() {
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
    if (this.props.location?.state?.user_phone) {
      var tel = this.props.location?.state?.user_phone;
      tel = '' + tel;
      var telsubstr = tel.substr(0, 3) + '****' + tel.substr(7);
      console.log(telsubstr);
      this.setState({ tel: telsubstr });
    }
    setTimeout(() => {
      this.formRef.current.resetFields();
    }, 300);
  }

  onFinish = values => {
    this.setState({ btnLoading: true });
    let body = new FormData();
    body.append('user_id', this.props.location?.state.user_id);
    body.append('code', values.Code);
    body.append('password', values.password);
    body.append('password_confirmation', values.confirm);
    body.append('deviceType', 'Web');
    body.append('firebaseToken', null);
    handelResetPassword(
      body,
      res => {
        this.setState({ btnLoading: false });

        if (res.data.code === 200) {
          localStorage.setItem('token', res.data.message);
          this.props.dispatch(
            setCurrentUser({ ...res.data.data.user, token: res.data.message })
          );
          this.props.dispatch(clearCart());
          setTimeout(() => {
            this.props.history.push(
              `/${this.props.currentLang}/password-changed`
            );
          }, 500);
        }
      },
      error => {
        console.log(error);
        this.setState({ btnLoading: false });
      }
    );
  };

  render() {
    const { btnLoading } = this.state;
    const { currentLang } = this.props;
    console.log('props', this.props);
    return (
      <div>
        <Helmet>
          <title>{meta.KhairZamanResetPassword[currentLang]}</title>
        </Helmet>
        <div className="auth-wrapper">
          <div className="auth-form-content">
            <I18Link to={{ pathname: '/' }} className="logo-holder">
              <img src={logo} alt="logo" className="typ-new-logo" />
            </I18Link>
            <p className="form-welcom">
              {localization.ResetPassword[currentLang]}
            </p>
            <p className="login-text">
              {localization.enternewpassword[currentLang]}
            </p>
            <Form
              name="basic"
              ref={this.formRef}
              onFinish={this.onFinish}
              autocomplete="off"
            >
              <Form.Item
                label={localization.Code[currentLang]}
                name="Code"
                rules={currentLang === 'en' ? codeValidation : codeValidationAr}
                className="input-holder group-floating-label"
              >
                <Input
                  autocomplete="new-password"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  className="input-control"
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={localization.Password[currentLang]}
                className="input-holder group-floating-label"
                rules={[
                  {
                    required: true,
                    message: `${localization.Pleaseinput[currentLang]} ${localization.Password[currentLang]}`,
                  },
                  {
                    min: 8,
                    message: `${localization.Password8characters[currentLang]}`,
                  },
                  {
                    max: 20,
                    message: `${localization.Password20characters[currentLang]}`,
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  autocomplete="new-password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  className="input-control"
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                label={localization.ReenterPassword[currentLang]}
                dependencies={['password']}
                className="input-holder group-floating-label"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: `${localization.Pleaseinput[currentLang]} ${localization.ReenterPassword[currentLang]}`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          `${localization.Pleaseinput[currentLang]} ${localization.twopasswords[currentLang]}`
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  className="input-control"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  loading={btnLoading}
                >
                  {localization.Reset[currentLang]}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});
const PhoneVerifyWithRouter = withRouter(ResetPassword);
export default connect(mapStateToProps)(PhoneVerifyWithRouter);
