import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import localization from '../../Localization/Localization.json';
import { Link as I18Link } from 'react-router-i18n';
import { connect } from 'react-redux';
import { Breadcrumb } from 'antd';
import './About.scss';
import { Helmet } from 'react-helmet';
import meta from '../../Localization/meta.json';

class About extends Component {
  render() {
    const { currentLang } = this.props;
    return (
      <div className="about-page-wrapper">
        <Helmet>
          <title>{meta.KhairZamanAbout[currentLang]}</title>
        </Helmet>
        <Header />
        <div className="container">
          {/* <div className="about-hero"></div> */}
          <div className="about-content">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <I18Link to={{ pathname: '/' }}>
                  {localization.home[currentLang]}
                </I18Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {localization.About[currentLang]}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="about-grid-wrapper">
              <div className="sec">
                <p>
                  <span>{localization.alzoghbiPharmacy[currentLang]}</span>{' '}
                  {localization.aboutSec1[currentLang]}
                </p>
                <p>
                  <span>{localization.alzoghbiPharmacy[currentLang]}</span>{' '}
                  {localization.aboutSec2[currentLang]}
                </p>
                <p>
                  <span>{localization.alzoghbiPharmacy[currentLang]}</span>{' '}
                  {localization.aboutSec3[currentLang]}
                </p>
              </div>
              <div className="sec center">
                <div className="title">
                  {localization.ourMission[currentLang]}
                </div>
                <div className="desc">
                  {localization.ourMissionText[currentLang]}
                </div>
              </div>
              <div className="sec center">
                <div className="title">
                  {localization.ourVision[currentLang]}
                </div>
                <div className="desc">
                  {localization.ourVisionText[currentLang]}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(About);
