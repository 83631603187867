import React from 'react';
import './SliderSection.scss';

const LeftArrow = ({ onClick, isDisabled }) => {
  return (
    <button
      className={`slick-prev ${isDisabled ? 'slick-disabled' : ''}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        className="slick-button-icon"
        viewBox="0 0 100 100"
      >
        <path
          d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
          className="arrow"
        ></path>
      </svg>
    </button>
  );
};

export default LeftArrow;
