import { Image, Radio, Form, Button, Spin, Modal, message } from 'antd';
import React, { Component } from 'react';
import { fallbackImg } from '../../constants/constants';
import './OrderAlternative.scss';
import del_icon from '../../assets/img/del.svg';
import localization from '../../Localization/Localization.json';
import { connect } from 'react-redux';
import {
  SelectOrderAlternatives,
  ShowOrderAlternatives,
} from '../../network/cart.network';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';

const { confirm } = Modal;

class OrderAlternative extends Component {
  formUserRef = React.createRef();
  state = {
    altValue: null,
    btnLoading: false,
    loading: false,
    altList: [],
  };

  componentDidMount() {
    this.setState({ loading: true });
    const { alternativeOrder } = this.props;
    ShowOrderAlternatives(
      alternativeOrder?.id,
      (res) => {
        this.setState({
          loading: false,
          altList: res?.data?.data?.Items.map((ele) => ({
            ...ele,
            required: true,
            canceled: false,
          })),
        });
      },
      (err) => {
        this.setState({ loading: false });
      }
    );
  }

  onChangeAlt = (val) => {
    this.setState({ altValue: val });
    const eleIdx = this.state.altList.findIndex((s) => +s.id === val);
    const newItemsList = this.state.altList;
    newItemsList[eleIdx].required = true;
    newItemsList[eleIdx].canceled = false;
    this.setState({ altList: newItemsList });
  };

  cancelItemAlternative = (ele) => {
    const item_id = '' + ele?.id;
    message.success(
      localization.Productcanceled[this.props.currentLang] + ele?.name
    );
    this.formUserRef.current.setFieldsValue({ [item_id]: null });
    const eleIdx = this.state.altList.findIndex((s) => s.id === ele?.id);
    const newItemsList = this.state.altList;
    newItemsList[eleIdx].required = false;
    newItemsList[eleIdx].canceled = true;
    this.setState({ altList: newItemsList });
  };

  onFinish = (values) => {
    let body = {
      items: [],
    };
    for (const item in values) {
      body.items.push({
        id: item,
        alternativeId: values[item] === undefined ? null : values[item],
      });
    }

    this.setState({ btnLoading: true });
    SelectOrderAlternatives(
      body,
      this.props?.alternativeOrder?.id,
      (res) => {
        this.setState({ btnLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          this.props.showAllorder();
        }
      },
      (error) => {
        this.setState({ btnLoading: false });
      }
    );
  };

  render() {
    const { altList, btnLoading, loading } = this.state;
    const { currentLang, alternativeOrder } = this.props;
    return (
      <Spin spinning={loading}>
        <div className='order-alt-wrapper'>
          <div className='order-alt-header'>
            <h4>
              {localization.Alternativesfororder[currentLang]} #
              {alternativeOrder?.id}
            </h4>
            <span>
              {localization.Pleaseselectalternativeforeachproduct[currentLang]}
            </span>
          </div>
          <Form name='basic' onFinish={this.onFinish} ref={this.formUserRef}>
            <div className='order-alt-list'>
              {altList?.map((ele, idx) => (
                <div className='order-alt-holder' key={ele.id}>
                  <div className='order-alt-info'>
                    <div className='order-alt-details'>
                      <div className='product-img'>
                        <Image
                          src={ele?.image || fallbackImg}
                          fallback={fallbackImg}
                          preview={false}
                        />
                      </div>
                      <div className='order-alt-name-price'>
                        <h5 className='order-alt--name'>
                          {ele?.name}{' '}
                          {ele?.canceled && (
                            <span className='state-can-holder'>
                              {' '}
                              - {localization.cancelled[currentLang]}{' '}
                            </span>
                          )}{' '}
                        </h5>
                        <p className='order-alt--price'>
                          {ele?.price}
                          {localization.AED[currentLang]}
                        </p>
                      </div>
                    </div>
                    <div
                      className='dlt-icon-holder'
                      onClick={() => this.cancelItemAlternative(ele)}
                    >
                      <p>{localization.Cancel[currentLang]}</p>
                      <img src={del_icon} alt='' />
                    </div>
                  </div>
                  <div className='order-alt-product-list'>
                    <Form.Item
                      name={`${ele?.id}`}
                      rules={[
                        {
                          required: ele?.required,
                          message: `${localization.SelectalternativeproductCancel[currentLang]}`,
                        },
                      ]}
                    >
                      <Radio.Group
                        optionType='button'
                        onChange={() => this.onChangeAlt(ele?.id)}
                      >
                        {ele?.alternatives?.map((alt) => (
                          <Radio value={alt.id}>
                            <div className='radio-item-card'>
                              <div className='product-img'>
                                <Image
                                  src={alt?.image || fallbackImg}
                                  fallback={fallbackImg}
                                  preview={false}
                                />
                              </div>
                              <div className='order-alt-name-price'>
                                <h5 className='order-alt--name'>{alt?.name}</h5>
                                <p className='order-alt--price'>
                                  {alt?.finalPrice}
                                  {localization.AED[currentLang]}
                                </p>
                              </div>
                              <div className='select-al-btn'>
                                {localization.select[currentLang]}{' '}
                              </div>
                            </div>
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              ))}
            </div>
            <Button
              size='large'
              style={{ width: 142 }}
              type='primary'
              htmlType='submit'
              loading={btnLoading}
            >
              {localization.submit[currentLang]}
            </Button>
            <Button
              size='large'
              style={{ width: 142 }}
              type='primary'
              className='primary-btn-light ml-20'
              onClick={this.props.showAllorder}
            >
              {localization.Cancel[currentLang]}
            </Button>
          </Form>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(withRouter(OrderAlternative));
