import React, { Component } from 'react';
import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import './HomeHero.scss';
import right_arrow from '../../assets/img/right_arrow.svg';
import left_arrow from '../../assets/img/left_arrow.svg';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img src={right_arrow} alt="" className={className} onClick={onClick} />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img src={left_arrow} alt="" className={className} onClick={onClick} />
  );
}

class HomeHero extends Component {
  render() {
    const { data } = this.props;
    console.log('HomeHero  render  data:', data);
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      autoplay: true,
      speed: 3000,
      arrows: false,
      // autoplaySpeed: 3000,
      pauseOnHover: true,
    };
    return (
      <div className="home-hero-slider">
        {/* <div className="container"> */}
        <div className="home-hero-slider-content">
          <div className="main-slider-holder">
            <Slider {...settings}>
              {data?.carousels?.map(slide => (
                <div
                  className="slide-holder"
                  key={slide.id}
                  onClick={() => {
                    // slide.searchKey && this.props.history.push(`/search/${slide.searchKey}`);
                  }}
                >
                  <a
                    target="_blank"
                    href={slide.url}
                    rel="noreferrer"
                    // download={slide.title}
                  >
                    <img src={slide.image} alt="" />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
          {/* <div className="two-slider-holder">
              <Slider {...settings}>
                {data?.carousels?.map((slide) => (
                  <div
                    className="slide-holder"
                    key={slide.id}
                    onClick={() => {
                      slide.searchKey &&
                        this.props.history.push(`/${this.props.currentLang}/search/${slide.searchKey}`);
                    }}
                  >
                    <img src={slide.image} alt="" />
                  </div>
                ))}
              </Slider>
              <Slider {...settings}>
                {data?.advertisements?.map((slide) => (
                  <div
                    className="slide-holder"
                    key={slide.id}
                    onClick={() => {
                      slide.searchKey &&
                        this.props.history.push(`/${this.props.currentLang}/search/${slide.searchKey}`);
                    }}
                  >
                    <img src={slide.image} alt="" />
                  </div>
                ))}
              </Slider>
            </div> */}
        </div>
        {/* </div> */}
      </div>
    );
  }
}

const HomeHeroWithRouter = withRouter(HomeHero);
const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(HomeHeroWithRouter);
