import { Button } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import localization from '../../Localization/Localization.json';
import placecart from '../../assets/img/order_placed.svg';
import { Helmet } from 'react-helmet-async';
import meta from '../../Localization/meta.json';
import './OrderDone.scss';
import { connect } from 'react-redux';
import { clearCart } from '../../redux/cart/cart.action';
class OrderDone extends Component {
  componentDidMount() {
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
    this.props.dispatch(clearCart());
  }

  render() {
    const { currentLang, location } = this.props;
    return (
      <div className="order-page-wrapper">
        <Helmet>
          <title>{meta.KhairZamanplaced[currentLang]}</title>
          <script>
            {`
              gtag('event', 'conversion', {
                  'send_to': 'AW-590585837/KlTxCN-Si6EDEO2_zpkC',
                  'transaction_id': ''
              })
              `}
          </script>
        </Helmet>
        <Header />
        <div className="container">
          <div className="order-content-wrapper">
            <div className="order-main">
              <p className="order-p-text">
                {localization.Orderplaced[currentLang]}
              </p>
              <p className="order-thank">{localization.Thanks[currentLang]}</p>
              <img src={placecart} alt="" />
              <p className="order-placed-p">
                {localization.Yourorderplacedsuccessfully[currentLang]}
              </p>
              <div className="order-placed-btns">
                <Button
                  type="primary"
                  size="large"
                  className="mr-20"
                  style={{ height: 'auto' }}
                >
                  <I18Link to={{ pathname: '/' }}>
                    {localization.home[currentLang]}
                  </I18Link>
                </Button>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  className="primary-btn-light"
                  style={{ height: 'auto' }}
                >
                  <I18Link
                    to={{
                      pathname: `/profile/3/ordrers`,
                    }}
                  >
                    {localization.Myordrers[currentLang]}
                  </I18Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}
const OrderDoneWithRouter = withRouter(OrderDone);
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(OrderDoneWithRouter);
