import { css } from '@emotion/css';
import { Button, Col, Form, message, Modal, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import useApi from '../../hooks/useApi';
import localization from '../../Localization/Localization.json';
import { fetchAddresses } from '../../network/profile.network';
import { setAddress } from '../../redux/user/user.action';
import MyAddresses from '../MyAddresses/MyAddresses';
import OwnUpload from '../own/OwnUpload';

function PrescriptionSend() {
  const PrescriptionSendStyles = css`
    width: 700px;
    max-width: 100%;
    margin: auto;
    padding: 10px;

    .title {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.42px;
      text-transform: capitalize;
      color: #000;
      margin-bottom: 15px;
    }

    .ant-col.ant-form-item-label {
      /* top: 10px; */
    }
    .input-select {
      .ant-select-selector {
        height: 50px;
        input {
          display: block;
          height: 50px;
        }
      }
      .ant-select-selection-item {
        padding-top: 14px;
        font-size: 12px;
      }
    }

    .input-upload {
      /* background-color: red; */
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn-upload {
        width: 350px;
        max-width: 100%;
        height: 50px;
        border-width: 3px;
      }
    }
    .ant-upload-list {
      margin-top: 10px;

      a[title='Preview file'] {
        display: none;
      }
    }
  `;
  const { currentLang, userAdress } = useSelector(s => s.user);
  console.log('PrescriptionSend  userAdress:', userAdress);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModAddrOpen, setIsModAddrOpen] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [btnLoading, setBtnLoading] = useState(false);
  //   const [addressList, setAddressList] = useState([]);
  //   console.log('PrescriptionSend  addressList:', addressList);
  const [loading, setLoading] = useState(false);
  const api = useApi();

  useEffect(() => {
    setLoading(true);
    fetchAddresses(
      res => {
        setLoading(false);
        if (res.data.data.addresses.length > 0) {
          //   this.setState({ showAddAdress: true });
        }
        const resData = res.data.data.addresses;
        dispatch(setAddress(resData));
        // setAddressList(resData);
      },
      error => {
        console.log(error);
        setLoading(false);
      }
    );
  }, []);
  const onChangeImage = e => {
    setFileList(e.fileList);
  };
  const sendPrescription = async data => {
    setBtnLoading(true);
    try {
      const res = await api.post(`prescriptions/send`, data);

      console.log('sendPrescription  res:', res);
      if (res?.code === 200) {
        message.success(res.message);
        form.resetFields();
        setFileList([]);
      } else {
        res.validation?.forEach(msg => {
          message.error(msg);
        });
      }
      setBtnLoading(false);
    } catch (error) {
      console.log('sendPrescription  error:', error);
      setBtnLoading(false);
    }
  };

  const onFinish = values => {
    console.log('onFinish  values:', values);
    console.log('onFinish  fileList:', fileList);
    // return;
    const fd = new FormData();
    fd.append('address_id', values?.address_id);
    fd.append('description', values?.description);
    fileList?.forEach((file, i) => {
      fd.append(`attachments[${i}]`, file?.originFileObj);
    });

    sendPrescription(fd);
  };

  return (
    <>
      <div className={PrescriptionSendStyles}>
        <div className="title">
          {localization.upload_your_prescription[currentLang]}
        </div>

        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={5} wrap={false}>
            <Col flex={'auto'}>
              <Form.Item
                label={localization.selectAddress[currentLang]}
                name="address_id"
                rules={[
                  {
                    required: true,
                    message: `${localization.Pleaseinput[currentLang]} 
                  ${localization.selectAddress[currentLang]}`,
                  },
                ]}
                className="input-holder group-floating-label no-icon"
              >
                <Select
                  className="input-control input-select"
                  options={userAdress?.map(el => ({
                    label: el?.address,
                    value: el?.id,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{ height: '50px' }}
                onClick={() => setIsModAddrOpen(true)}
              >
                {localization.add_new[currentLang]}
              </Button>
            </Col>
          </Row>

          <Form.Item
            label={`${localization.description[currentLang]} (${localization.optional[currentLang]})`}
            name="description"
            className="input-holder group-floating-label no-icon"
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              allowClear
              className="input-control"
            />
          </Form.Item>

          <Form.Item
            //   label={localization.upload_prescription[currentLang]}
            name="attachments"
            rules={[
              {
                required: true,
                message: `${localization.Pleaseinput[currentLang]}
                        ${localization.upload_prescription[currentLang]}`,
              },
            ]}
          >
            <OwnUpload
              // accept=".png,.jpg,.JPEG,ico"
              maxCount={3}
              fileList={fileList}
              setFileList={setFileList}
              onChange={onChangeImage}
              multiple
              className="upload-hero"
              listType="picture-card"
            >
              <Button type="link" className="btn-upload">
                {localization.upload_prescription[currentLang]}
              </Button>
            </OwnUpload>
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              block
              loading={btnLoading}
            >
              {localization.Send[currentLang]}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        wrapClassName="address-modal"
        destroyOnClose
        onCancel={() => setIsModAddrOpen(false)}
        title=""
        footer={null}
        open={isModAddrOpen}
      >
        <MyAddresses
          ispopup={true}
          noSetAddressLocal={true}
          closeModal={() => setIsModAddrOpen(false)}
        />
      </Modal>
    </>
  );
}

export default PrescriptionSend;
