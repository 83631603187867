import React, { Component } from "react";
import { Form, Input, Button, Checkbox, InputNumber, Row, Col } from "antd";
import { Link, withRouter } from "react-router-dom";
import "../../scss/pages/auth.scss";
import {
  phoneValidationAR,
  phoneValidationEN,
} from "../../constants/constants";
import {
  userRegister,
  userLoginFb,
  userLogin,
} from "../../network/auth.network";
import { setBranchUser, setCurrentUser } from "../../redux/user/user.action";
import localization from "../../Localization/Localization.json";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login";
// import logo from "../../assets/img/Logo.png"
import logo from "../../assets/img/logogreen-croped.png";

import googleLogo from "../../assets/img/go.svg";
import {
  MailOutlined,
  UserOutlined,
  PhoneOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { fetchPresist } from "../../redux/presistData/presistData.action";
import { clearCart } from "../../redux/cart/cart.action";
import ResNavBar from "../../components/ResNavBar/ResNavBar";
import { GoogleLogin } from "react-google-login";
import { Link as I18Link } from "react-router-i18n";
import { Helmet } from "react-helmet-async";
import meta from "../../Localization/meta.json";

class Register extends Component {
  state = {
    btnLoading: false,
    isLoginValid: false,
  };
  componentDidMount() {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }

  onGoogleLoginSuccess = (response) => {
    // console.log('Login Success', response);

    this.setState({ btnLoading: true });
    let body = new FormData();
    body.append("deviceType", "Web");
    body.append("from", "google");
    body.append("token", response.accessToken);

    userLoginFb(
      body,
      (res) => {
        this.setState({ btnLoading: false });
        if (res.data.code === 200) {
          localStorage.setItem("token", res.data.message);
          this.props.dispatch(fetchPresist(null));
          this.props.dispatch(clearCart());
          this.props.dispatch(setBranchUser(null));
          this.props.dispatch(
            setCurrentUser({
              ...res.data.data.user,
              token: res.data.message,
              isSocial: true,
            })
          );
          setTimeout(() => {
            this.props.history.push(`/${this.props.currentLang}/`);
          }, 500);
        }
        if (res.data.code === 105) {
          setTimeout(() => {
            this.props.history.push({
              pathname: `/${this.props.currentLang}/phone-verification`,
              state: { user_id: res.data.item },
            });
          }, 200);
        }
      },
      (error) => {
        console.log(error);
        this.setState({ btnLoading: false });
      }
    );
  };

  onFailureSuccess = (res) => {
    // console.log('Login Failed:', res);
  };

  responseFacebook = (response) => {
    // console.log(response);
    if (response) {
      this.setState({ btnLoading: true });
      let body = new FormData();
      body.append("deviceType", "Web");
      body.append("from", "facebook");
      body.append("token", response.accessToken);
      // body.append("providerId", response.id);
      userLoginFb(
        body,
        (res) => {
          this.setState({ btnLoading: false });
          //
          if (res.data.code === 200) {
            localStorage.setItem("token", res.data.message);
            this.props.dispatch(fetchPresist(null));
            this.props.dispatch(clearCart());
            this.props.dispatch(setBranchUser(null));
            this.props.dispatch(
              setCurrentUser({
                ...res.data.data.user,
                token: res.data.message,
                isSocial: true,
              })
            );
            setTimeout(() => {
              this.props.history.push(`/${this.props.currentLang}/`);
            }, 500);
          }
          if (res.data.code === 105) {
            setTimeout(() => {
              this.props.history.push({
                pathname: `/${this.props.currentLang}/phone-verification`,
                state: { user_id: res.data.item },
              });
            }, 200);
          }
        },
        (error) => {
          console.log(error);
          this.setState({ btnLoading: false });
        }
      );
    }
  };

  onFinish = (values) => {
    this.setState({ btnLoading: true });
    let body = new FormData();
    // console.log(values)
    body.append("first_name", values.Firstname);
    body.append("last_name", values.Lastname);
    body.append("phone", values.Phonenumber);
    body.append("password", values.password);
    body.append("password_confirmation", values.confirm);
    body.append("email", values.Emailaddress);
    userRegister(
      body,
      (res) => {
        this.setState({ btnLoading: false });
        if (res.data.code === 200) {
          // this.props.dispatch(setCurrentUser(res.data.user))
          body.append("username", values.Emailaddress);
          body.append("deviceType", "Web");
          body.append("deviceId", `${localStorage.getItem("deviceId")}`);

          userLogin(
            body,
            (res) => {
              if (res.data.code === 200) {
                console.log("login", res.data);
                localStorage.setItem("token", res.data.message);
                this.props.dispatch(
                  setCurrentUser({
                    ...res.data.data.user,
                    token: res.data.message,
                  })
                );
                this.props.dispatch(setBranchUser(null));
                this.props.dispatch(fetchPresist(null));
                this.props.dispatch(clearCart());
                setTimeout(() => {
                  this.props.history.push(`/${this.props.currentLang}/`);
                }, 500);
              }
            },
            (error) => {
              console.log(error);
            }
          );
          console.log(res);
          // setTimeout(() => {
          //   this.props.history.push({
          //     pathname: `/${this.props.currentLang}/phone-verification`,
          //     state: { user_id: res.data.item, user_phone: res.data.message },
          //   });
          // }, 200);
        }
      },
      (error) => {
        this.setState({ btnLoading: false });
      }
    );
  };

  render() {
    const { btnLoading, isLoginValid } = this.state;
    const { currentLang } = this.props;
    return (
      <div>
        <Helmet>
          <title>{meta.KhairZamanRegister[currentLang]}</title>
        </Helmet>
        <div className="auth-wrapper auth-login-wrapper">
          <div className="auth-login-img">
            {/* <img src={Herologo} alt="logo" /> */}
          </div>
          <div className="auth-form-content">
            <I18Link to={{ pathname: "/" }} className="logo-holder">
              <img src={logo} alt="logo" className="typ-new-logo" />
            </I18Link>
            <p className="form-welcom">
              {localization.Createaccount[currentLang]}
            </p>
            <Form name="basic" onFinish={this.onFinish}>
              <Row gutter={20}>
                <Col sm={24} md={12}>
                  <Form.Item
                    label={localization.Firstname[currentLang]}
                    name="Firstname"
                    rules={[
                      {
                        required: true,
                        message: `${localization.Pleaseinput[currentLang]} ${localization.Firstname[currentLang]}`,
                      },
                    ]}
                    className="input-holder group-floating-label"
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      className="input-control"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item
                    label={localization.Lastname[currentLang]}
                    name="Lastname"
                    rules={[
                      {
                        required: true,
                        message: `${localization.Pleaseinput[currentLang]} ${localization.Lastname[currentLang]}`,
                      },
                    ]}
                    className="input-holder group-floating-label"
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      className="input-control"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col sm={24} md={12}>
                  <Form.Item
                    label={localization.Phonenumber[currentLang]}
                    name="Phonenumber"
                    rules={
                      currentLang === "en"
                        ? phoneValidationEN
                        : phoneValidationAR
                    }
                    className="input-holder group-floating-label"
                  >
                    <Input
                      prefix={<PhoneOutlined className="site-form-item-icon" />}
                      className="input-control"
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} md={12}>
                  <Form.Item
                    label={localization.EmailAddress[currentLang]}
                    name="Emailaddress"
                    rules={[
                      {
                        required: true,
                        message: `${localization.Pleaseinput[currentLang]} ${localization.EmailAddress[currentLang]}`,
                      },
                      {
                        type: "email",
                        message: localization.Emailvalid[currentLang],
                      },
                    ]}
                    className="input-holder group-floating-label"
                  >
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      className="input-control"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col sm={24} md={12}>
                  <Form.Item
                    name="password"
                    label={localization.Password[currentLang]}
                    className="input-holder group-floating-label"
                    rules={[
                      {
                        required: true,
                        message: `${localization.Pleaseinput[currentLang]} ${localization.Password[currentLang]}`,
                      },
                      {
                        min: 8,
                        message: `${localization.Password8characters[currentLang]}`,
                      },
                      {
                        max: 20,
                        message: `${localization.Password20characters[currentLang]}`,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      className="input-control"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item
                    name="confirm"
                    label={localization.ReenterPassword[currentLang]}
                    dependencies={["password"]}
                    className="input-holder group-floating-label"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: `${localization.Pleaseinput[currentLang]} ${localization.ReenterPassword[currentLang]}`,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              `${localization.twopasswords[currentLang]}`
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      className="input-control"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* <div className="facebook-login-holder">
                <p>{localization.Continuewith[currentLang]}</p>
                <FacebookLogin
                  appId="851655875731830"
                  fields="name,email,picture"
                  cssClass="my-facebook-button-class"
                  textButton=""
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                    >
                      <g transform="translate(-0.016 -0.439)">
                        <rect
                          width="50"
                          height="50"
                          fill="#1877f2"
                          rx="25"
                          transform="translate(0.016 0.439)"
                        />
                        <path
                          fill="#fff"
                          d="M13.939 13.4h2.478l.991-3.965h-3.469V7.452c0-1.021 0-1.983 1.983-1.983h1.487v-3.33A27.92 27.92 0 0 0 14.576 2a4.3 4.3 0 0 0-4.6 4.659v2.776H7V13.4h2.974v8.426h3.965z"
                          transform="translate(12.825 13.304)"
                        />
                      </g>
                    </svg>
                  }
                  callback={this.responseFacebook}
                />
                <GoogleLogin
                  clientId="581919882916-o4otgniv1avn2vbng67128f6h2eq7l7j.apps.googleusercontent.com"
                  buttonText
                  onSuccess={this.onGoogleLoginSuccess}
                  onFailure={this.onFailureSuccess}
                  cookiePolicy={'single_host_origin'}
                  icon={false}
                  className="googleIconStyle"
                >
                  <img src={googleLogo} alt="google" />
                </GoogleLogin>
              </div> */}
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  loading={btnLoading}
                >
                  {localization.Register[currentLang]}
                </Button>
              </Form.Item>
            </Form>
            <p className="user-text">
              {localization.Alreadyaccount[currentLang]}
              <I18Link to={{ pathname: "/login" }}>
                {localization.Login[currentLang]}
              </I18Link>
            </p>
          </div>
        </div>
        <ResNavBar />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});

const RegisterWithRouter = withRouter(Register);
export default connect(mapStateToProps)(RegisterWithRouter);
