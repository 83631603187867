const socialLinks = {
  phone: '+20225218080',
  hotline: '15672',
  email: 'info@alzoghbipharmacy.com',
  facebook: 'https://www.facebook.com/AlZoghbiPharm/',
  instagram: 'https://www.instagram.com/alzoghbipharmacy/',
  office_1: {
    url: 'https://maps.app.goo.gl/sYch2Y1ZNfdzvV698?g_st=ic',
    en: {
      title: 'Nasr City branch',
      lable: '65 abbas al akkad street',
    },
    ar: {
      title: 'فرع مدينة ناصر',
      lable: 'مدينة نصر 65 عباس العقاد تقاطع مصطفى النحاس',
    },
  },
  office_2: {
    url: 'https://maps.app.goo.gl/sHyTw2z6BDM5i7HX8',
    en: {
      title: 'Fifth Settlement branch',
      lable: 'north plus mall in north teseen street infront of waterway 2',
    },
    ar: {
      title: 'فرع التجمع الخامس',
      lable: 'التجمع الخامس التسعين الشمالى أمام واتر واى2 بمول نورث بلس',
    },
  },
};

export default socialLinks;
