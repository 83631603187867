import React, { Component } from 'react';
import { ListPrescriptionOrder } from '../../network/profile.network';

import './PrescriptionOrder.scss';
import { Spin, Result, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CancellationReasons from '../CancellationReasons/CancellationReasons';
import { fetchPrescriptionOrder } from '../../redux/presistData/presistData.action';
import OrderAlternative from '../OrderAlternative/OrderAlternative';
import { Link as I18Link } from 'react-router-i18n';

class PrescriptionOrder extends Component {
  state = {
    isLoading: false,
    showOrderDetails: false,
    showReasonsModal: false,
    orderList: null,
    orderTracking: null,
    originalOrder: null,
    alternativeOrder: null,
    isOrderAlt: false,
  };

  componentDidMount() {
    const { presistData } = this.props;

    if (presistData === null) {
      this.setState({ isLoading: true });
      this.presistMyOrder();
    } else {
      this.setState({ orderList: presistData });
      this.presistMyOrder();
    }
  }

  presistMyOrder = () => {
    ListPrescriptionOrder(
      res => {
        console.log('PrescriptionOrder  res:', res);
        this.setState({ isLoading: false });
        this.props.dispatch(fetchPrescriptionOrder(res.data.data));
        this.setState({ orderList: res.data.data });
      },
      error => {
        this.setState({ isLoading: false });
      }
    );
  };

  showOrderList = () => {
    this.setState({ orderTracking: null, showOrderDetails: false });
  };

  handelCancelOrder = orderId => {
    this.setState({ cancelorderId: orderId, showReasonsModal: true });
  };

  closeCancelOrderModal = () => {
    this.setState({ showReasonsModal: false });
  };

  showAllorder = () => {
    this.setState({ isOrderAlt: false }, () => {
      this.presistMyOrder();
    });
  };

  render() {
    const {
      orderList,
      alternativeOrder,
      isLoading,
      showOrderDetails,
      showReasonsModal,
      cancelorderId,
      isOrderAlt,
    } = this.state;
    console.log('MyOrder  render  orderList', orderList);

    const { localization, currentLang } = this.props;
    return (
      <>
        <Spin spinning={isLoading}>
          {isOrderAlt ? (
            <OrderAlternative
              showAllorder={this.showAllorder}
              alternativeOrder={alternativeOrder}
            />
          ) : (
            <div className="user-order-wrapper">
              {orderList?.oldPrescriptions?.length === 0 &&
              orderList?.pendingPrescriptions?.length === 0 ? (
                <Result
                  status="404"
                  title=""
                  subTitle={localization.sorrynoorder[currentLang]}
                  extra={
                    <Button type="primary" size="large">
                      <I18Link to={{ pathname: '/' }}>
                        {localization.BackToHome[currentLang]}
                      </I18Link>
                    </Button>
                  }
                />
              ) : (
                <>
                  <CancellationReasons
                    closeModal={() => {
                      this.setState({ showReasonsModal: false });
                      this.presistMyOrder();
                    }}
                    showModalVisible={showReasonsModal}
                    orderId={cancelorderId}
                    currentLang={currentLang}
                    localization={localization}
                    fromOrderList
                  />
                  <div className="user-order-type">
                    <div className="user-order--header">
                      {orderList?.pendingPrescriptions?.length > 0 && (
                        <p>{localization.Onprocessorders[currentLang]}</p>
                      )}
                    </div>
                    {orderList?.pendingPrescriptions?.map(order => (
                      <I18Link
                        // to={{
                        //   pathname: `/order-info/${order.id}`,
                        //   search: '?type=prescriptions',
                        // }}
                        to={`/order-info/${order.id}?type=prescription`}
                        className="process-order-card"
                        style={{ borderColor: `${order.status.color}` }}
                        key={order.id}
                      >
                        <div className="process-order-info">
                          <div className="process-order-number">
                            <p className="order-title">
                              {localization.OrderID[currentLang]} :
                            </p>
                            <p className="order-num">#{order.id}</p>
                            <p
                              className="order-status"
                              style={{ color: `${order.status.color}` }}
                            >
                              - {order.status.name}
                            </p>
                          </div>
                          <time>{order.createdAt}</time>
                        </div>
                        {order.status.id !== 3 && (
                          <div className="process-order-action">
                            {order.hasAlternatives ? (
                              <p
                                className="cancel-order alternative-order-btn"
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  this.setState({
                                    isOrderAlt: true,
                                    alternativeOrder: order,
                                  });
                                }}
                              >
                                {localization.showalternatives[currentLang]}
                              </p>
                            ) : (
                              <p
                                className="cancel-order"
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  this.handelCancelOrder(order.id);
                                }}
                              >
                                {localization.Cancelorder[currentLang]}
                              </p>
                            )}
                          </div>
                        )}
                      </I18Link>
                    ))}
                  </div>

                  {
                    <div className="user-order-type user-order-type-prev">
                      {orderList?.oldPrescriptions?.length > 0 && (
                        <p className="old-order-tilte">
                          {localization.YourPreviousorders[currentLang]}
                        </p>
                      )}
                      {orderList?.oldPrescriptions?.length > 0 &&
                        orderList?.oldPrescriptions?.map(order => (
                          <I18Link
                            // to={{
                            //   pathname: `/order-info/${order.id}?type=prescription`,
                            //   query: { isOld: true },
                            // }}
                            to={`/order-info/${order.id}?type=prescription`}
                            className="process-order-card"
                            key={order.id}
                          >
                            <div className="process-order-info">
                              <div className="process-order-number">
                                <p className="order-title">
                                  {localization.OrderID[currentLang]} :
                                </p>
                                <p className="order-num">#{order.id}</p>
                                {order.cancelled ? (
                                  <p
                                    className="order-status"
                                    style={{ color: `#f82727` }}
                                  >
                                    - {localization.CancelledOrder[currentLang]}
                                  </p>
                                ) : (
                                  <p
                                    className="order-status"
                                    style={{ color: `#00672C` }}
                                  >
                                    - {localization.Completed[currentLang]}
                                  </p>
                                )}
                              </div>
                              <time>{order.createdAt}</time>
                            </div>
                          </I18Link>
                        ))}
                    </div>
                  }
                </>
              )}
            </div>
          )}
        </Spin>
      </>
    );
  }
}

const PrescriptionOrderWithRouter = withRouter(PrescriptionOrder);
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
  presistData: state.presistData.presistPrescriptionOrder,
});

export default connect(mapStateToProps)(PrescriptionOrderWithRouter);
