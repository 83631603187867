import { css } from '@emotion/css';
import { Tooltip } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import localization from '../../Localization/Localization.json';

function FloatButton() {
  const FloatButtonStyles = css`
    position: fixed;
    bottom: 25px;
    bottom: 65px;
    inset-inline-end: 25px;
    z-index: 999;
    background-color: #fff;
    border-radius: 50%;

    .whatsapp-btn {
      display: inline-block;
      width: auto;
      height: auto;
      padding: 5px;
      svg {
        font-size: 40px;
      }
    }
  `;

  const tooltipContentStyles = css`
    padding: 10px;
    .title {
      font-weight: 500;
      font-size: 18px;
      text-transform: capitalize;
      color: #00672c;
      margin-bottom: 5px;
    }
    .desc {
      font-size: 13px;
      color: #666;
    }
  `;
  const { currentLang } = useSelector(s => s.user);

  return (
    <div className={FloatButtonStyles}>
      <Tooltip
        // open
        color="#fff"
        placement="bottomLeft"
        arrowPointAtCenter
        mouseLeaveDelay={5}
        title={
          <div className={tooltipContentStyles}>
            <div className="title">{localization.hello[currentLang]}! 👋</div>
            <div className="desc">{localization.whatsMsg[currentLang]}</div>
          </div>
        }
        className="tooltip"
      >
        {/* <Button type="link" icon={<WhatsAppOutlined />} onClick= /> */}
        <a
          href={`https://wa.me/message/SVHBCZRXHIUJM1`}
          target="_blank"
          rel="noreferrer"
          className="whatsapp-btn"
        >
          <WhatsAppOutlined className="icon whatsapp" />
        </a>
      </Tooltip>
    </div>
  );
}

export default FloatButton;
