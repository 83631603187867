import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../../components/Header/Header';
import HomeHero from '../../components/HomeHero/HomeHero';
import SliderSection from '../../components/SliderSection/SliderSection';
import LoadingScreen from '../../components/Loading/Loading';
import TrendingBrand from '../../components/TrendingBrand/TrendingBrand';
import Footer from '../../components/Footer/Footer';
import ResNavBar from '../../components/ResNavBar/ResNavBar';

import { fetchCart } from '../../network/product.network';
import { fetchHome } from '../../network/home.network';

import localization from '../../Localization/Localization.json';

import { addProductToCart, clearCart } from '../../redux/cart/cart.action';
import {
  setAddressUser,
  setBranchUser,
  setPaymentUser,
} from '../../redux/user/user.action';
import { fetchPresist } from '../../redux/presistData/presistData.action';

import { withRouter } from 'react-router';

class Home extends Component {
  state = {
    home_details: null,
    loading: false,
  };

  componentDidMount() {
    const { presistData } = this.props;
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
    if (presistData === null) {
      this.setState({ loading: true });
      this.fetchHomeDetails();
    } else {
      this.setState({ home_details: presistData });
      this.fetchHomeDetails();
    }

    fetchCart(
      res => {
        this.props.dispatch(clearCart());
        setTimeout(() => {
          res.data.data?.cart?.items?.forEach(ele => {
            const isProductExistInStorage = this.props.cartItems?.some(
              product => +product.id === +ele.productId
            );
            if (!isProductExistInStorage) {
              this.props.dispatch(
                addProductToCart({
                  ...ele,
                  productId: +ele.id,
                  id: +ele.productId,
                  inCart: {
                    itemId: +ele.id,
                    quantity: +ele.quantity,
                  },
                })
              );
            }
          });
        }, 300);
      },
      error => {
        console.log(error);
      }
    );
  }

  fetchHomeDetails = () => {
    fetchHome(
      res => {
        console.log(res);
        this.setState({ loading: false, shapeLoading: false });
        this.props.dispatch(fetchPresist(res.data.data));
        this.setState({ home_details: res.data.data });
        this.props.dispatch(setAddressUser(res.data.data?.addresses));
        this.props.dispatch(setPaymentUser(res.data.data.paymentType));
        this.props.dispatch(setBranchUser(res.data.data.branch));
        setTimeout(() => {
          this.forceUpdate();
          // console.log(this.state, "check state.................................")
        }, 1000);
      },
      error => {
        this.setState({ loading: false, shapeLoading: false });
        console.log(error);
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { currentLang } = this.props;
    if (currentLang !== prevProps.currentLang) {
      this.setState({ shapeLoading: true });
      this.fetchHomeDetails();
    }
  }

  render() {
    const { home_details, loading, shapeLoading } = this.state;
    const { presistData } = this.props;
    const { currentLang } = this.props;

    return (
      <div className="page-content-wrapper home-wrapper">
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            <Header fetchHomeDetails={this.fetchHomeDetails} />
            {/* <div className="bg-wrapper" style={{ paddingBottom: 20 }}> */}
            <HomeHero data={home_details} />
            <div className="suumer-hero-wrapper">
              <div className="container">
                {/* <div className="suumer-hero-img">
                    <img style={{borderRadius : 10}} src={currentLang === "en" ? banneren : bannerar} alt="" />
                    <Button type="primary" size="large"
                      onClick={() => this.props.history.push(`/${currentLang}/search/${"K-Zaman Private"}`)}
                    >{localization.showproducts[currentLang]}</Button>
                  </div> */}
              </div>
            </div>

            <TrendingBrand
              // title={localization.Trendingcategories[currentLang]}
              title={'Popular categories'}
              data={home_details?.featuredCategories || []}
            />

            <div className="deal-recommend-wrapper">
              {home_details?.products && (
                <SliderSection
                  title={localization.DealsRecommendedforyou[currentLang]}
                  shapeLoading={shapeLoading}
                  sliderList={home_details?.products || []}
                />
              )}
            </div>
            {/* </div> */}

            <Footer fetchHomeDetails={this.fetchHomeDetails} />
            <ResNavBar />
          </>
        )}
      </div>
    );
  }
}

const HomeWithRouter = withRouter(Home);
const mapStateToProps = state => ({
  productsList: state.products.productsList,
  currentLang: state.user.currentLang,
  currentUser: state.user.currentUser,
  cartItems: state.cart.cartItems,
  presistData: state.presistData.presistHomeData,
});

export default connect(mapStateToProps)(HomeWithRouter);
