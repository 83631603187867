import { Spin } from 'antd'
import React from 'react'
import loading from "../../assets/img/loading.gif"
const loadingStyle = {
    backgroundColor: "#fff",
    width : "100%",
    height : "100%",
    display : "flex",
    alignItems : "center", 
    justifyContent : "center",
    position : "fixed",
    zIndex : 4444,
}
export default function LoadingScreen() {
    return (
        <div className="loading-wrapper" style={loadingStyle}> 
        <Spin style={{fontSize : 30}} />
            {/* <img className="" src={loading} alt="" />   */}
            {/* <img className="pluse-animation" src={logo} alt="logo" />   */}
        </div> 
    )
}
