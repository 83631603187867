import {
  Button,
  Image,
  message,
  Input,
  Spin,
  Form,
  Row,
  Col,
  Modal,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { CheckOutlined } from '@ant-design/icons';
import localization from '../../Localization/Localization.json';
import { Helmet } from 'react-helmet-async';
import './Checkout.scss';
import AddressModal from '../../components/AddressModal/AddressModal';
import { fallbackImg } from '../../constants/constants';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import {
  applyPromoCode,
  applyRemovePromoCode,
  PlaceOrder,
  getPayOnlineUrl,
} from '../../network/cart.network';
import offer_icon from '../../assets/offer_icon.svg';
import { fetchCart } from '../../network/product.network';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import { clearCart } from '../../redux/cart/cart.action';
import {
  setAddressUser,
  setBranchUser,
  setPaymentUser,
} from '../../redux/user/user.action';
import SelectPayMethod from '../../components/SelectPayMethod/SelectPayMethod';
import BranchModal from '../../components/BranchModal/BranchModal';
import meta from '../../Localization/meta.json';
import { limitText } from '../../helpers/limitText';

const { TextArea } = Input;
class CheckoutPage extends Component {
  formUserRef = React.createRef();
  state = {
    showModalVisible: false,
    commentLoading: false,
    orderLoading: false,
    showPayModal: false,
    openBranch: false,
    codeLoading: false,
    payModalVisible: false,
    Promo_code: '',
    branchModalVisible: true,
    comment: '',
    loading: true,
    cart: null,
  };

  componentDidMount() {
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
    fetchCart(
      res => {
        const cart = res.data.data.cart;
        this.setState({
          loading: false,
          cart,
          Promo_code: cart.totals.promocode,
        });
        if (cart.totals.promocode) {
          this.formUserRef?.current?.setFieldsValue({
            Promocode: cart.totals.promocode || '',
          });
        }
        if (cart.items.length === 0) {
          this.props.history.push(`/${this.props.currentLang}/cart`);
        }
      },
      error => {
        this.setState({ loading: false });
        console.log(error);
      }
    );
  }

  handelPromoCode = () => {
    const { Promo_code, cart } = this.state;
    this.setState({ codeLoading: true });
    let body = new FormData();
    body.append('promocode', Promo_code);
    applyPromoCode(
      body,
      res => {
        this.setState({ codeLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          this.setState({ cart: { ...cart, totals: res.data.data } });
        }
      },
      error => {
        this.setState({ codeLoading: false });
        console.log(error);
      }
    );
  };

  handelRemovePromoCode = () => {
    const { cart, Promo_code } = this.state;
    this.setState({ codeLoading: true });
    let body = new FormData();
    body.append('promocode', Promo_code);
    applyRemovePromoCode(
      body,
      res => {
        this.setState({ codeLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          this.setState({ cart: { ...cart, totals: res.data.data.totals } });
          this.formUserRef?.current?.setFieldsValue({
            Promocode: '',
          });
        }
      },
      error => {
        this.setState({ codeLoading: false });
        console.log(error);
      }
    );
  };

  handelPlaceOrder = () => {
    const { comment } = this.state;
    this.setState({ orderLoading: true });
    let body = new FormData();
    body.append('comment', comment);
    body.append('orderFrom', 'Web');
    PlaceOrder(
      body,
      res => {
        this.setState({ orderLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          this.props.dispatch(clearCart());
          this.props.dispatch(setAddressUser(null));
          this.props.dispatch(setPaymentUser(null));
          // this.props.dispatch(setBranchUser(null))

          setTimeout(() => {
            this.props.history.push({
              pathname: `/${this.props.currentLang}/order-placed`,
              state: { orderID: res.data.item },
            });
          }, 500);
        }
      },
      error => {
        this.setState({ orderLoading: false });
        console.log(error);
      }
    );
  };

  handelPayPlaceOrder = async () => {
    this.setState({ orderLoading: true });
    const url = await getPayOnlineUrl();
    this.setState({ orderLoading: false });
    // console.log('CheckoutPage  handelPayPlaceOrder=async  url:', url);
    if (url) {
      window.open(url, '_self');
    } else {
      console.log('No PayOnlineUrl!!');
      message.error('Something went wrong with online payment!');
    }
  };

  setPaymentCallBack = Type => {
    const { cart } = this.state;
    this.setState({
      cart: {
        ...cart,
        paymentType: Type,
        totals: { ...cart.totals, paymentType: Type },
      },
    });
  };

  render() {
    const {
      codeLoading,
      showModalVisible,
      loading,
      cart,
      orderLoading,
      showPayModal,
      openBranch,
      branchModalVisible,
      payModalVisible,
    } = this.state;
    console.log('CheckoutPage  render  cart:', cart);
    const { currentLang, currentAddress, currentPayment, currentBranch } =
      this.props;
    return (
      <div className="checkout-page-wrapper">
        <Header />
        {cart?.paymentType?.id === 3 && (
          <Helmet>
            <title>{meta.KhairZamanCheckout[currentLang]}</title>
          </Helmet>
        )}

        <Modal
          open={payModalVisible}
          onCancel={() => this.setState({ payModalVisible: false })}
        >
          <div className="loader"></div>
        </Modal>

        {openBranch && (
          <BranchModal
            closeModal={() => this.setState({ branchModalVisible: false })}
            branchModalVisible={branchModalVisible}
          />
        )}
        <AddressModal
          ispopup
          closeModal={() => this.setState({ showModalVisible: false })}
          showModalVisible={showModalVisible}
        />
        <SelectPayMethod
          closeModal={() => this.setState({ showPayModal: false })}
          showModalVisible={showPayModal}
          currentLang={currentLang}
          localization={localization}
          setPaymentCallBack={Type => this.setPaymentCallBack(Type)}
        />
        <div className="container">
          <Spin spinning={loading}>
            <div className="checkout-content-wrapper">
              <div className="checkout-main">
                {/* <div className="checkout-header">
                <p>{localization.OrderSummary[currentLang]}</p> 
                <Button loading={orderLoading} size="large" type="primary" onClick={this.handelPlaceOrder}>
                  {localization.PlaceOrder[currentLang]}
                  </Button>
              </div> */}
                {cart?.totals?.promoCode && (
                  <div className="saved-holder">
                    <p>
                      <img src={offer_icon} alt="" />{' '}
                      {localization.Yousaved[currentLang]}{' '}
                      <span>
                        {cart?.totals?.discount} {localization.AED[currentLang]}{' '}
                      </span>{' '}
                      {localization.throughoffers[currentLang]}{' '}
                    </p>
                  </div>
                )}
                <div className="checkout-items-holder">
                  {/* <p className="checkout-items-title">{localization.Items[currentLang]}</p>  */}
                  <div className="checkout-items-list">
                    {cart?.items?.map(card => (
                      <div className="item-holder" key={card.id}>
                        <div className="product-img">
                          <Image
                            src={card?.image_link || card?.image || fallbackImg}
                            fallback={fallbackImg}
                            preview={false}
                          />
                        </div>
                        <div className="product-details-wrapper">
                          <div className="product-info">
                            <h6 className="product-name">
                              {card?.productName || card?.name}
                            </h6>
                            <span className="product-count">
                              x{card.quantity}
                            </span>
                          </div>

                          <div className="price-holder">
                            <p className="price-after">
                              {card?.finalPrice}{' '}
                              <span>{localization.AED[currentLang]}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="checkout-total-holder">
                    <div className="total-row">
                      <h4>{localization.Subtotal[currentLang]}</h4>
                      <h5>
                        {cart?.totals?.subtotal} {localization.AED[currentLang]}
                      </h5>
                    </div>
                    {cart?.totals?.discountPercentage !== 0 && (
                      <div className="total-row">
                        <h4>{localization.Discount[currentLang]}</h4>
                        <h5>
                          {/* {parseFloat(
                            +cart?.totals?.discountPercentage || 0
                          ).toFixed(0)} */}
                          {cart?.totals?.discountPercentage}%
                        </h5>
                      </div>
                    )}
                    <div className="total-row">
                      <h4>{localization.deliverycharges[currentLang]}</h4>
                      <h5>
                        {cart?.totals?.deliveryCharge}{' '}
                        {localization.AED[currentLang]}
                      </h5>
                    </div>
                  </div>

                  <div className="checkout-final-total">
                    <div className="total-row">
                      <h4>
                        {localization.TOTAL[currentLang]}
                        <span>
                          ({localization.PricesincludeVAT[currentLang]})
                        </span>
                      </h4>
                      <h5>
                        {cart?.totals?.total} {localization.AED[currentLang]}
                      </h5>
                    </div>
                  </div>
                  <Form
                    name="basic"
                    onFinish={this.onFinishOrder}
                    ref={this.formUserRef}
                  >
                    <div className="cart-aside-promo">
                      <Row gutter={20}>
                        <Col xs={24} lg={12}>
                          <Form.Item
                            label={localization.Commentoptional[currentLang]}
                            name="Comment"
                            className="input-holder group-floating-label no-icon group-floating-label-textarea"
                          >
                            <TextArea
                              autoSize={{ minRows: 1, maxRows: 6 }}
                              className="input-control"
                              onChange={e =>
                                this.setState({ comment: e.target.value })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24}></Col>
                        <Col xs={24} lg={12} className="promocode-input-holder">
                          <Form.Item
                            label={localization.Promocode[currentLang]}
                            name="Promocode"
                            rules={[
                              {
                                required: true,
                                message: localization.Promocode[currentLang],
                              },
                            ]}
                            // className='group-floating-label no-icon'

                            className="input-holder group-floating-label no-icon"
                          >
                            <Input
                              className="input-control"
                              onChange={e =>
                                this.setState({ Promo_code: e.target.value })
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={24}
                          lg={12}
                          className="promocode-input-holder  promocode-btn-wrapper"
                        >
                          {cart?.totals?.promocode ? (
                            <div className="promocode-text-holder">
                              {/* <p className="applied-txt">
                                {localization.Applied[currentLang]}{' '}
                                <CheckOutlined />
                              </p> */}
                              <Button
                                size="large"
                                style={{
                                  width: 125,
                                  fontSize: 14,
                                  backgroundColor: '#fe5252',
                                  border: 0,
                                }}
                                type="primary"
                                onClick={this.handelRemovePromoCode}
                                loading={codeLoading}
                              >
                                {localization.Remove[currentLang]}
                              </Button>
                              <p className="applied-txt">
                                {localization.Youhave[currentLang]}
                                {cart?.totals?.discountPercentage}%
                                {localization.dicount[currentLang]}
                              </p>
                            </div>
                          ) : (
                            <Button
                              size="large"
                              style={{ width: 125, fontSize: 14 }}
                              type="primary"
                              htmlType="submit"
                              loading={codeLoading}
                              onClick={this.handelPromoCode}
                              disabled={cart?.totals?.promocode}
                            >
                              {localization.Apply[currentLang]}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="checkout-main-footer">
                <div className="cart-aside-card">
                  {/* {currentBranch && (
                    <div className="aside-card-row">
                      <h5 className="card-row-title">
                        {localization.branch[currentLang]}
                      </h5>
                      <div className="row-info">
                        <h4 className="card-row-title">
                          {currentBranch?.name}
                        </h4>
                        <p
                          className="card-row-btn"
                          onClick={() =>
                            this.setState({
                              openBranch: true,
                              branchModalVisible: true,
                            })
                          }
                        >
                          {localization.CHange[currentLang]}
                        </p>
                      </div>
                    </div>
                  )} */}
                  {
                    <div className="aside-card-row">
                      <h5 className="card-row-title">
                        {localization.Address[currentLang]}
                      </h5>
                      <div className="row-info">
                        <h4 className="card-row-title">
                          {limitText(currentAddress?.fullAddress, 25)}
                        </h4>
                        <p
                          className="card-row-btn"
                          onClick={() =>
                            this.setState({ showModalVisible: true })
                          }
                        >
                          {localization.CHange[currentLang]}
                        </p>
                      </div>
                    </div>
                  }
                  {
                    <div className="aside-card-row">
                      <h5 className="card-row-title">
                        {localization.Payment[currentLang]}
                      </h5>
                      <div className="row-info">
                        <h4 className="card-row-title">
                          {currentPayment?.type}
                        </h4>
                        <p
                          className="card-row-btn"
                          onClick={() => this.setState({ showPayModal: true })}
                        >
                          {localization.CHange[currentLang]}
                        </p>
                      </div>
                    </div>
                  }
                </div>
                <div className="checkout-main-footer-btns">
                  {cart?.paymentType?.id === 3 ? (
                    <Button
                      loading={orderLoading}
                      size="large"
                      type="primary"
                      onClick={this.handelPayPlaceOrder}
                      style={{ minWidth: 'max-content' }}
                    >
                      {localization.payPlaceOrder[currentLang]}
                    </Button>
                  ) : (
                    <Button
                      loading={orderLoading}
                      size="large"
                      type="primary"
                      onClick={this.handelPlaceOrder}
                    >
                      {localization.PlaceOrder[currentLang]}
                    </Button>
                  )}
                  <Button
                    size="large"
                    type="primary"
                    className="primary-btn-light"
                  >
                    <I18Link to={{ pathname: '/cart' }}>
                      {localization.Back[currentLang]}
                    </I18Link>
                  </Button>
                </div>
              </div>
            </div>
          </Spin>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const CheckoutWithRouter = withRouter(CheckoutPage);
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
  currentAddress: state.user.currentAddress,
  currentPayment: state.user.currentPayment,
  // currentBranch: state.user.currentBranch,
});

export default connect(mapStateToProps)(CheckoutWithRouter);
