import React from 'react';
import socialLinks from '../../constants/social-links';
import faqLocal from '../../Localization/faq.json';
import './FAQS.scss';

function TermsAr({ currentLang }) {
  return (
    <div className="terms-article-holder">
      <h5 className="top-heading">
        {faqLocal.WelcometoKheirZaman[currentLang]}
      </h5>
      <p>
        يمكنك أن تجد هنا جميع الشروط والبنود التي تنطبق على أداء خدماتنا المقدمة
        بأعلى مستويات المعايير القياسية لدينا. فإن كنت تقرأ هذا الموضوع فإن هذا
        يعني أن لديك الذكاء والوعي لقراءتها بعناية قبل الاستعانة بخدماتنا.{' '}
      </p>
      <h2>
        نرجو قراءة الشروط والبنود التالية بحرص. فهي تحتوى على معلومات هامة من
        جهة حقوقك والتزاماتك. وكذلك الحدود والقيود والاستثناءات التي تنطبق على
        مشترياتك.{' '}
      </h2>
      <h4> النطاق العام</h4>
      <ul>
        <li>
          يقدم "صيدلية الزغبى" خدمات توصيل الطلبات التي تتم على أساس برامج للسلع
          المختلفة مثل الأطعمة والمشروبات ومنتجات البقالة الأخرى (يشار إليها
          بصفة إجمالية باسم منتجات البقالة). وتنطبق هذه الشروط (شروط الخدمة)
          عندما تستخدم مواقع صيدلية الزغبى ماركت" (ويشار إليها بصفة إجمالية باسم
          الخدمات).{' '}
        </li>
      </ul>
      <p>وبموجب استخدامك للخدمات فإنك توافق تلقائياً على شروط الخدمة. </p>
      <h4> معلومات الاتصال الشخصية</h4>
      <h3>جمع المعلومات:</h3>
      <p>
        يقوم صيدلية الزغبى بجمع المعلومات التالية من مستخدمي الموقع: الاسم
        وعنوان الشارع والمنطقة والمدينة، وارقام الهاتف والاتصال، وعنوان البريد
        الإلكتروني وبيانات الدفع الخاصة بالمستخدم في حالة الدفع ببطاقة الائتمان
        أو بطاقة الكترونية
      </p>
      <h3>استخدام المعلومات:</h3>
      <p>
        يقوم صيدلية الزغبى باستخدام المعلومات لانشاء حسابات المستخدمين على
        الموقع من اجل التواصل مع المستخدمين بشأن خدمات صيدلية الزغبى ولتقديم
        خدمات إضافية للمستخدمين، والعروض الترويجية والعروض الخاصة، وفرض رسوم على
        عمليات الشراء التي تتم من خلال موقع صيدلية الزغبى
      </p>
      <h3>ازالة المعلومات:</h3>
      <p>
        في حال رغب المستخدم في عدم استخدام خدمات موقع صيدلية الزغبى يمكن ازالة
        جميع معلومات الاتصال الشخصية الخاصة به. يمكن للمستخدم التواصل معنا
        بالاتصال على الخط الساخن لصيدلية الزغبى 16007 داخل مصر أو عبر البريد
        الاكتروني{' '}
        <a href={`mailto:${socialLinks.email}`}>{socialLinks.email}</a> وتوضيح
        المعلومات الذي يرغب في ازالتها.
      </p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <h4> إجراءات التسجيل والطلب</h4>
      <h3>2 – 1 التسجيل</h3>
      <p>
        أنت المستخدم الوحيد المعتمد للحساب (الحساب) الذي تقوم بإنشائه لاستخدام
        الخدمات. وتمثل عملية التسجيل التي تقوم بها موافقتك وقبولك لشروط الخدمة
        وسياسة الخصوصية، وتفيد أيضاً بأن عمرك لا يقل عن 21 عاماً (واحد وعشرين).
        يتم التسجيل مجاناً. وطلبك للخدمة لا يمنحكم أي استحقاقات معينة. ويلزم أن
        تكون البيانات المطلوبة للتسجيل والمقدمة من المستخدم للنظام كاملة ودقيقة.
        إن المستخدم مسئول عن تحديث بياناته / بياناتها والتي يمكن تعديلها في أي
        وقت من خلال الواجهة الخاصة بالتعامل مع الخدمة. ولا تتحمل " صيدلية
        الزغبى" أي مسئولية عن استخدام حساب المستخدم وهي تخلي مسئولياتها صراحة من
        أي التزام من هذا النوع. وإذا كان لديك شك بأن هناك أي طرف غير مصرح له
        يستخدم حسابك فعليك على الفور أن تخطر صيدلية الزغبى ماركت فوراً لتظل
        أمناً من خلال أن ترسل لنا عن طريق البريد الإلكتروني على العنوان{' '}
        <span>{socialLinks.email}</span> وبموجب تقديمك لرقم الموبايل الخاص بك
        إلى صيدلية الزغبى ماركت عند استخدام الخدمات فإنك توافق بناءً عليه على أن
        نستخدم رقم الموبايل الخاص بك للاتصال بك مباشرة لغرض أداء الخدمات ويشمل
        ذلك على سبيل المثال لا الحصر إرسال الإشعارات في بعض الأحيان والرسائل
        النصية مع العروض الترويجية وتحديثات الخدمة والوصول لك للمتابعة بشأن جودة
        خدماتنا.{' '}
      </p>
      <h3>2 – 2 تقديم طلب الشراء والعلاقة التعاقدية</h3>
      <p>
        من خلال تقديم الطلب عبر الموقع الإلكتروني يؤكد المستخدم دقة جميع
        المعلومات التي يقدمها. ويتم تأكيد الطلبات للمستخدم من خلال واجهة تقديم
        الخدمة. ويمكن أن تقرر " صيدلية الزغبى" لأي سبب من الأسباب أن ترفض قبول
        الطلب وترفض أدائه. ويمكن للمستخدم أن يحدد ميعاداً بالوقت والتاريخ أو
        الأسبوع أو الشهر للطلب بحسب توافق الخدمة.{' '}
      </p>
      <h3>2 – 3 مجموعة من الشروط</h3>
      <p>قد يختلف نطاق وأسعار مواد البقالة ويتوقف ذلك على مكان التسليم. </p>
      <h3>2 – 4 الحدود الخاصة بالحجم والكميات</h3>
      <p>
        قد يتم الاتصال بالمستخدم من قبل " صيدلية الزغبى" لتأكيد الطلب في الحالات
        التالية :
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        • إذا كان إجمالي عناصر الطلب يتجاوز 100 عنصر أو إذا كان الطلب يحتوي على
        15 أو أكثر من نفس العناصر. • إذا كان الطلب المقدم يتطلب التعامل مع أكثر
        من شخص واحد من موظفي التسليم للتعامل بسبب القيود الخاصة بالكميات والحجم.
        وفي جميع الأحوال تحتفظ "صيدلية الزغبى" بحقها في الحد من كميات التسليم
        لمنتجات معينة أو إذا لزم عدم تسليم منتج معين بالمرة وذلك بحسب توافر مواد
        البقالة.{' '}
      </p>
      <h3>تعديل وإلغاء أوامر الشراء</h3>
      <p>
        قد لا يتمكن المستخدم من تعديل الطلب بمجرد تأكيده طبقاً للخصائص الفنية
        المقدمة من الخدمة. والطلب المؤكد يمكن إلغائه خلال فترة زمنية محدودة و
        حتى يتم صرفه من أجل تسليمه وذلك طبقاً للمواصفات الفنية للخدمة.{' '}
      </p>
      <h3>استيفاء الطلبات غير الكاملة والبدائل</h3>
      <p>
        إن الهدف الأساسي لصيدلية الزغبى ماركتس هو توريد جميع المنتجات المطلوبة
        بالكمية الصحيحة وبالمستويات عالية الجودة. ويقر المستخدم بأن مواد البقالة
        قد تخضع لتوافر الكميات وأيضاً احتمالات الأخطاء البشرية. وتحتفظ "صيدلية
        الزغبى" بحقها في تعديل طلباتكم كلياً أو جزئياً في أي وقت من الأوقات
        واستبعاد أي عناصر غير متاحة أو تالفة أو تعرضت للضرر أو التلف أو غير
        متوافرة لأي سبب أخر وبدون أي مسئولية أو تعويض كنتيجة لهذا التعديل وذلك
        للإكمال الناجح لطلبكم. إن غرضنا الأساسي أن نبذل كل ما في وسعنا للمحافظة
        على سعر البنود البديلة لكي تظل مماثلة للمنتجات والعلامات التجارية التي
        طلبتها أصلاً ولكن في حالة التعديل ببدائل ذات قيمة أعلى فإن هذا قد يؤدي
        إلى زيادة السعر. إننا نبذل كل ما وسعنا لضمان أن جميع العناصر يتم بيانها
        في موقعنا وتكون متوافرة لطلبها. ولكن إذا كانت هناك أي منتجات تطلبها وغير
        متوافرة أو نفذت كمياتها فقد نرسل لكم إخطاراً بذلك حتى يمكنكم اختيار
        البديل. وإذا كان هناك أحد العناصر لم يتم تسليمه على الرغم من وجوده في
        الفاتورة فإن القيمة المعنية سيتم إضافتها لحساب المستخدم في وقت مناسب بعد
        علم "صيدلية الزغبى" بذلك. ولا توجد أي توريدات لاحقة إلزامية ولن يكون
        للعميل الحق في المطالبة بأي تعويض إضافي. وإذا حدث لاي سب من الأسباب ولم
        نتمكن نتيجة لظروف خارجة عن إرادتنا في الحدود المناسبة أن نورد عنصراً
        معيناً فلن نكون مسئولين أمام المستخدم كنتيجة لذلك. ونرجو ملاحظة أننا سوف
        نحاول أن نرسل إخطاراً للمستخدم بالمنتجات البديلة في حالة عدم توافر
        المنتجات المختارة ويمكن للمستخدم عندئذً رفض هذه البدائل. وعلى الرغم من
        أننا سوف نحاول دائماً تغطية طلباتكم حسب اللازم، ولكن الطلبات بالكميات
        الكبيرة غير المعتادة من منتجات مختلفة أو منتج معين يمكن تلبيتها فقط بحسب
        اختيار وتقدير المتجر.{' '}
      </p>
      <h4>التسليم</h4>
      <h3>تسليم مواد البقالة</h3>
      <p>
        سيتم تسليم البقالة مباشرة على عنوان التسليم المحدد من قبل المستخدم. ويتم
        تنفيذ عمليات التسليم إما من خلال خدمة التوصيل الشخصي من المتجر أو يتم
        تسليمها على الباب الأمامي للمساكن الخاصة (والتي يمكن الوصول إليها بقدر
        الإمكان) ولمكتب الاستقبال بالنسبة للمستخدمين من المنشآت التجارية.{' '}
      </p>
      <h3>أوقات التسليم والالتزام بمدد التسليم</h3>
      <p>
        تسعى "صيدلية الزغبى" لتسليم البقالة خلال 60 إلى 90 دقيقة متوسط وقت
        التسليم ويتوقف ذلك على الموقع أو الجدول الزمني.{' '}
      </p>
      <h3>إيصال التسليم</h3>
      <p>يلتزم متجرنا بالمسئولية في أن يقدم للمستخدم الإيصال عند التسليم. </p>
      <h3>إلغاء التسليم من قبل "صيدلية الزغبى"</h3>
      <p>
        إذا حدث لأي أسباب خارجة عن سيطرة صيدلية الزغبى مثل عنوان التسليم غير
        الصحيح أو عدم وجود تصريح بالوصول أو سوء الأحوال الجوية أو الأحوال
        المماثلة، وإذا تبين أنه من المستحيل أو أن الأمر يتم بصعوبة بالغة كبيرة
        أن يتم تسليم المنتجات بصورة ناجحة ففي هذه الحالات فإن "صيدلية الزغبى"
        سيكون لها الحق في إلغاء طلب المستخدم وفي هذه الحالة فإن المستخدم ليس له
        الحق في التعويض ولكن في حالة الدفع على الإنترنت يتم استرداد الأموال حيث
        تقوم "صيدلية الزغبى" بهذا الإجراء في أقرب وقت ممكن لإلغاء الطلب وتكون
        المسئولية على البنك في تحويل المبلغ لحساب المستخدمين ويستغرق ذلك 3 – 5
        يوم عمل تقريباً.{' '}
      </p>
      <h4>الأسعار والدفع</h4>
      <p>
        تسعى "صيدلية الزغبى" لتزويدك بأسعار دقيقة يتم تحديثها ومعلومات عن توافر
        المنتجات والعروض الترويجية. والتناقضات والاختلافات المحتملة وإنك توافق
        بناءً عليه على عدم تحميل صيدلية الزغبى ماركت بالمسئولية في مثل هذه
        الحالات.{' '}
      </p>
      <h4>الأسعار</h4>
      <p>
        يتم عرض جميع الأسعار بالعملة المحلية للبلد وستكون الأسعار المعروضة في
        الموقع شاملة ضريبة القيمة المضافة. وحيثما كانت البضائع يتم تحديد أسعارها
        بالوزن (مثل الفواكه واللحوم والجبن وغيرها) يتم تطبيق السعر الأساسي لكل
        وحدة. وكمية هذه البضائع التي تم تسليمها بالفعل وبالتالي السعر المطلوب قد
        تختلف عن الكمية المطلوبة أصلاً. وبالنسبة للمنتجات الطازجة السابقة
        التعبئة والتي تحمل علامات وملصقات بالسعر فإن السعر المطبق هو السعر
        الساري المفعول عند إعداد الطلب. وتحتفظ "صيدلية الزغبى" بحقها في تعديل
        الأسعار للتحديث في أي وقت.{' '}
      </p>
      <h4>أساليب الدفع / الائتمان</h4>
      <p>
        يمكنك الاختيار من بين الطرق التالية للدفع عند التسليم ويتوقف ذلك على
        المنتجات وأساليب ووسائل الإرسال وحسب التوافق الفني :{' '}
      </p>
      <ul>
        <li> ماكينة الدفع الإئتمانى المتنقلة عند التسليم.</li>
        <li> الدفع نقداً عند الاستلام.</li>
        <li>•الدفع الإلكتروني على الإنترنت</li>
      </ul>
      <p className="start-parg">
        نحن نقبل المدفوعات عبر الإنترنت باستخدام بطاقات الائتمان فيزا وماستر
        كارد وأميكس (سارية المفعول فقط في مصر) وبطاقات الائتمان / الخصم بالجنيه
        المصري.{' '}
      </p>
      <p className="start-parg">
        في حالة وجود اي نزاع أو دعاوي بخصوص هذا الموقع أو بالارتباط به فإنها
        تخضع ويتم تفسيرها طبقاً لقوانين مصر.{' '}
      </p>
      <p className="start-parg">مصر هي بلد وموطن شركتنا.</p>
      <p className="start-parg">
        محظور على القصر أقل من 18 سنة التسجيل كمستخدم في هذا الموقع وغير مسموح
        لهم بالتعامل أو استخدام هذا الموقع.{' '}
      </p>
      <p className="start-parg">
        إذا قمت بالدفع مقابل منتجاتنا في موقعنا فإن التفاصيل التي ستطلب منك
        لتقديمها يتم إرسالها مباشرة لمزود خدمات الدفع الخاص بنا من خلال الاتصال
        الآمن.{' '}
      </p>
      <p className="start-parg">
        على حامل البطاقة الاحتفاظ بنسخة من سجلات المعاملات وسياسات وقواعد
        التاجر.{' '}
      </p>
      <p>
        يمكن للمستخدم اختيار وسيلة الدفع المفضلة لديه. ووسائل الدفع المتوافرة
        يتم عرضها للاختيار عند الانتهاء من الطلب وإذا حدث لأي سبب من الأسباب وتم
        رفض الدفع عبر الإنترنت فإن "صيدلية الزغبى" سيكون لها الحق في أن تعرض على
        المستخدم خيار الدفع نقداً عند التسليم أو من خلال ماكينة الدفع الإئتمانى
        المتنقلة عند التسليم إذا كان ذلك متاحاً.{' '}
      </p>
      <h3>إقرار المنتجات</h3>
      <h4>معلومات المنتجات </h4>
      <p>
        نحن نحرص على تحديث معلومات المنتجات في الخدمة بصفة منتظمة وفي حالات
        نادرة قد تختلف المعلومات عن التفاصيل المطبوعة على عبوة المنتج وفي هذه
        الحالة فإن المعلومات على العبوة تكون لها الأولوية. ونظراً لأن الوصفات
        والتركيبات قد تتغير في أي وقت فإننا نوصي بأن تراجع دائماً قائمة المكونات
        والمعلومات المتعلقة بالحساسية المدونة على العبوة.{' '}
      </p>
      <h4>ضمان إبلاغ البيانات</h4>
      <p>
        • نظراً للأوضاع الحالية للتكنولوجيا لا يمكن ضمان أن اتصال البيانات عبر
        الإنترنت سيكون خالياً من الأخطاء و / أو متاحاً في جميع الأوقات.ولذلك لا
        تتحمل "صيدلية الزغبى" أي مسئولية من جهة التوافر الثابت والمتواصل بدون
        انقطاع للشراء عبر الإنترنت أو الأعطال والأخطاء الفنية والإلكترونية أثناء
        معاملات البيع وبصفة خاصة أي تأخير في تداول أو قبول الطلبات.{' '}
      </p>
      <h4>خدمة العملاء والشكاوي</h4>
      <p>
        توفر خدمة العملاء لدى "صيدلية الزغبى" المعلومات التي تتعلق بأي أسئلة أو
        مشكلات أو شكاوي بخصوص خدمات صيدلية الزغبى ماركت. ويمكن للمستخدمين
        الاتصال بصيدلية الزغبى ماركت من خلال البريد الإلكتروني (
        <a href={`mailto:${socialLinks.email}`}>{socialLinks.email}</a>) أو
        إرسال رسالة من خلال التطبيق أو الاتصال بخط خدمة العملاء 19073. ويلزم
        الإبلاغ عن العناصر الناقصة فوراً عند استلام مواد البقالة وبخلاف ذلك فإن
        مواد البقالة تعتبر أنها قد تم قبولها من قبل المستخدم على أنها بحالة
        ممتازة. ويلزم الإبلاغ عن العيوب الكامنة وغير الظاهرة لخدمة عملاء "صيدلية
        الزغبى" فور ظهورها وعلى الرغم من أنه في هذه الحالات قد تكون هناك قيود
        على الإقرار بالخطأ{' '}
      </p>
      <h3>الشروط العامة</h3>
      <h4>حماية البيانات</h4>
      <p>
        • يتم التعامل مع البيانات التي يتم جمعها على أنها سرية ويتم التعامل بها
        بالنية الحسنة. وعند التسجيل يتم جمع بياناتك فقط لأغراض الأعمال والتسويق
        في شكل معلومات شخصية مثل الاسم الأول والاسم الأخير والعنوان وعنوان
        البريد الإلكتروني ورقم التليفون. وبالإضافة إلى البيانات التي يتم إدخالها
        صراحة فإن المعلومات يتم جمعها تلقائياً من ملفات التسجيل عندما تدخل
        لواجهة تقديم الخدمات. وتقوم "صيدلية الزغبى" بالتمييز بين البيانات
        الرئيسية (على سبيل المثال عنوان الموقع IP والوقت وتاريخ الدخول) وبين
        بيانات النشاط (على سبيل المثال اسم الملف الذي تم الدخول عليه والمسارات
        التي تم النقر عليها). وللأغراض الإحصائية فإن هذه البيانات يتم تقييمها مع
        إخفاء البيانات الشخصية.{' '}
      </p>
      <h4> إخلاء المسئولية</h4>
      <p className="upper-parg">
        يتم استخدام الدعم على مسئولياتكم تماماً. وقد يتم إجراء تعديلات بصفة
        دورية في البرامج والتطبيقات ويمكن أن تتم هذه التعديلات في أي وقت بدون
        إخطار لكم. ويتم تقديم الدعم على حالته بدون أي ضمانات من أي نوع سواء
        صراحة أو ضمناً ويشمل ذلك على سبيل المثال لا الحصر الضمانات بخصوص قابلية
        التسويق والصلاحية لغرض معين وعدم المخالفة للشروط. ولا تقدم صيدلية الزغبى
        ماركت أي ضمانات أو تعهدات بخصوص دقة أو اكتمال المحتوى المقدم من خلال
        الدعم أو محتوى أي تطبيقات برامج مرتبطة بتطبيقات برامج "صيدلية الزغبى".
        ولا تتحمل "صيدلية الزغبى" أي مسئولية بخصوص أي من (1) الأخطاء أو عدم
        الدقة في المحتوى أو(2) الإصابة الشخصية أو أضرار الممتلكات من أي نوع
        والناتجة بسبب دخولكم واستخدامكم لتطبيقات برامج "صيدلية الزغبى" أو الدعم
        و (3) أي دخول بدون وجه حق أو استخدام للسيرفرات الأمنة الخاصة بصيدلية
        الزغبى ماركت و / أو أي من وجميع المعلومات الشخصية و / أو المعلومات
        المالية ولا تضمن صيدلية الزغبى ماركت أن تطبيقات البرامج سوف تعمل بشكل
        خالي من الأخطاء أو أن تطبيقات البرامج والسيرفر الخاص بها خالية من
        فيروسات الكمبيوتر والمواد الضارة الأخرى. وإذا كان استخدامك لتطبيقات
        البرامج يؤدي إلى الاحتياج لخدمة المعدات أو استبدال المعدات أو البيانات
        فإن "صيدلية الزغبى" لن تكون مسئولة عن هذه التكاليف. و"صيدلية الزغبى" وفي
        أقصى الحدود المسموح بها طبقاً للقانون تخلي مسئولياتها من جهة جميع
        الضمانات سواء كانت صريحة أو ضمنية ويشمل ذلك على سبيل المثال لا الحصر
        الضمان بخصوص قابلية التسويق أو عدم المخالفة لحقوق الغير وضمان الصلاحية
        لأغراض معينة ولا تقدم "صيدلية الزغبى" أي ضمانات بخصوص دقة واكتمال
        وفعالية الخدمات أو تقديمها في الوقت المطلوب بخصوص كافة المحتويات
        والخدمات والدعم والبرامج والنصوص والأشكال التصويرية أو الروابط و"صيدلية
        الزغبى" ومانحو التراخيص لا يمكنهم الضمان ولا يضمنون أي معلومات شخصية
        مقدمة منكم وأنها قد تتعرض لإساءة توجيهها أو استخدامها أو اعتراضها أو
        حذفها أو إتلافها أو استخدامها من قبل الغير.{' '}
      </p>
      <h4>تحديد المسئولية</h4>
      <p className="upper-parg">
        إنكم توافقون على أن "صيدلية الزغبى" لن تكون مسئولة عن أي أضرار مباشرة أو
        غير مباشرة أو خاصة أو مترتبة أو جزائية والناتجة عن أو المرتبطة بكل من
        (1) استخدامكم للدعم و (2) مسئولية أو صلاحية أي عميل أو (3) ما يتعلق
        بالأداء أو التصفح في تطبيقات البرامج أو الرابط الخاصة بكم لتطبيقات
        البرامج الأخرى من تطبيقات البرامج وحتى ولو تم إخطار صيدلية الزغبى ماركت
        باحتمالات هذه الأضرار. وإنكم توافقون أيضاً على أن "صيدلية الزغبى" لن
        تكون مسئولة عن أي أضرار ناتجة من انقطاع أو إيقاف أو إنهاء الخدمات ويشمل
        ذلك على سبيل المثال لا الحصر أي أضرار مباشرة أو غير مباشرة أو خاصة أو
        مترتبة أو جزائية وسواء كان أو لم يكن هناك مبررات لهذا الانقطاع أو
        الإنهاء أو الإيقاف أو إذا كان ذلك بسبب الإهمال أو بصورة متعمدة أو غير
        مقصودة أو يمكن أولا يمكن تجنبها. وعلى جميع الأحوال فإن المسئولية
        الإجمالية على "صيدلية الزغبى" بخصوصكم من جهة أي خسائر تنشأ كنتيجة لذلك
        لا تتجاوز المبالغ المدفوعة منكم لصيدلية الزغبى ماركت بناءً عليه. وبعض
        الاختصاص القضائية لا تسمح بهذا التحديد أو استبعاد الضمانات أو المسئولية
        عن الأضرار المترتبة أو غير المباشرة وهكذا فإن هناك بعض الحدود المذكورة
        عاليه لا تنطبق عليكم وإذا كان هناك أي اختصاص قضائي يعترض على القيود أو
        استثناء هذه الضمانات فإن هذا الشرط يعتبر قابلاً للانفصال عن هذا العقد
        وتظل بقية الشروط الأخرى بكامل مفعولها وتأثيرها.{' '}
      </p>
      <h4>التنازل</h4>
      <p>
        أي تنازل عن أي حقوق أو تعويضات من قبل "صيدلية الزغبى" لن يكون ساري
        المفعول إلا إذا تم تحريرياً مع التوقيع من الممثل المعتمد لصيدلية الزغبى.
        والتقصير من جانب صيدلية الزغبى ماركت في الممارسة أو الإلزام بأي من
        الحقوق المتاحة لها بموجب شروط الخدمة لا يعتبر تنازلاً أو تعديلاً لأي من
        هذا الحقوق أو يؤدي ذلك إلى منعها من الممارسة أو الإلزام بهذه الحقوق في
        أي وقت أو أوقات لاحقة.{' '}
      </p>
      <h4>التعويض وتجنيب الضرر</h4>
      <p>
        إنك توافق بناءً عليه على تعويض "صيدلية الزغبى" وتجنيبها الضرر وكذلك من
        يتبعها من المسئولين والمديرين والعاملين والوكلاء والشركات التابعة لها
        (وكل منها يسمى باسم الطرف الذي يتم تعويضه) من وضد جميع الخسائر والدعاوي
        والإجراءات والتكاليف والأضرار والغرامات والنفقات ويشمل ذلك على سبيل
        المثال لا الحصر رسوم ونفقات المحامين والتي قد يتعرض لها الطرف الذي يتم
        تعويضه والناتجة أو المرتبطة بسبب استخدامك غير المصرح به لتطبيقات البرامج
        أو اي مخالفة من جانبكم لشروط الخدمة ويشمل ذلك على سبيل المثال لا الحصر
        أي مخالفة فعلية أو مزعومة لأي قوانين أو لوائح محلية أو فيدرالية أو أي
        قرارات أو أوامر إدارية أو قواعد أو لوائح. وسترسل "صيدلية الزغبى" إخطاراً
        لكم فوراً بأي دعاوي أو إجراءات هكذا وسيكون لها الحق في متابعة الدفاع في
        هذه الإجراءات على نفقتكم وفي الدفاع في أي من هذه الدعاوي والإجراءات.{' '}
      </p>
      <h4>الإنهاء</h4>
      <p>
        يمكن صيدلية الزغبى ماركت تعديل أو إيقاف الخدمات بحسب اختيارها المطلق أو
        يمكنها التعديل أو الإيقاف أو الإنهاء لحقك في دخول الخدمات أو الدعم لأي
        سبب من الأسباب بإخطار أو بدون إخطار لكم وبدون مسئولية تجاهكم أو تجاه
        الغير وبالإضافة إلى إيقاف أو إنهاء دخولك إلى الخدمة أو الدعم فإن "صيدلية
        الزغبى" تحتفظ بحقها في اتخاذ الإجراءات القانونية المناسبة والتي تشمل على
        سبيل المثال لا الحصر الملاحقة المدنية أو الجنائية أو التحفظية. وحتى بعد
        إنهاء حقك في استخدام الدعم فإن حسابك يظل ملزماً ضدك ويمكنك إنهاء الحساب
        في أي وقت ولكن "صيدلية الزغبى" لا تضمن إنهائه كنتيجة للتوافر الفنية.
        وجميع الشروط التي بحكم طبيعتها تظل مستمرة سوف تستمر سارية المفعول بعد
        إنهاء الحساب.{' '}
      </p>
      <h4>القانون السائد وانفصال الشروط</h4>
      <p>
        يخضع هذا العقد لقوانين مصر وسيكون للمحاكم الاختصاص القضائي الحصري ومكان
        النظر في القضية لأي نزاع ينشأ بخصوص أو بالارتباط بهذا العقد ويوافق كل
        طرف بناءً عليه على الاختصاص القضائي لهذه المحاكم وبدون النظر لمبادئ
        تعارض القوانين. إذا تبين أن أي شرط في هذا العقد غير ساري المفعول في أي
        محكمة ذات اختصاص قضائي فإن عدم صلاحية هذا الشرط لا يؤثر على صلاحية بقية
        شروط العقد والتي تظل بكامل مفعولها وتأثيرها. وأي عرض على أي منتجات أو
        خصائص أو تطبيقات على هذه التطبيقات والبرامج ستكون باطلة ولاغية حيثما
        كانت محظورة.{' '}
      </p>
      <h4>تعديلات العقد</h4>
      <p>
        تحتفظ "صيدلية الزغبى" بحقها وبحسب اختيارها المطلق في التعديل أو التغيير
        أو الإضافة أو الاستكمال أو الحذف لأي شروط أو بنود في هذا العقد ويكون ذلك
        ساري المفعول بإخطار أو بدون إخطار مسبق واستخدامك المستمر لتطبيقات
        البرامج أو الدعم بعد إجراء أي تعديل في هذا العقد يعتبر قبولاً كاملاً من
        جانبكم وبشكل غير قابل للإلغاء لأي من وجميع هذه التعديلات نرجو الاتصال
        بنا إذا كانت لديك أي تعليقات أو أسئلة نرجو عدم التردد في الاتصال بنا على
        العنوان <a href={`mailto:${socialLinks.email}`}>{socialLinks.email}</a>
      </p>
      <h4>تفاصيل الاتصال</h4>
      <p>
        مصر، زهراء المعادي - المنطقة الصناعية، القاهرة (تليفون
        <a href={`tel:${socialLinks.phone}`}>{socialLinks.phone}</a> | وبريد
        إلكتروني :{' '}
        <a href={`mailto:${socialLinks.email}`}>{socialLinks.email}</a>
      </p>
    </div>
  );
}

export default TermsAr;
