import { Form, Image, Input, Skeleton } from 'antd';
import React from 'react';
import localization from '../../Localization/Localization.json';

import { fallbackImg } from '../../constants/constants';
import truckLine from '../../assets/pickup-car.png';

function RenderOrder({ isLoading, currentLang, orders, formUserRef }) {
  console.log('RenderOrder  orders:', orders);
  return (
    <div className="single-order-content">
      <div className="single-order-content-items">
        <div className="checkout-items-list order-list-items">
          {isLoading ? (
            <>
              <Skeleton avatar title={false} active></Skeleton>
              <Skeleton avatar title={false} active></Skeleton>
            </>
          ) : (
            orders?.items?.map(item => (
              <div className="item-holder orders-holder" key={item.id}>
                <div className="product-img">
                  <Image
                    src={item?.image_link || item?.image || fallbackImg}
                    fallback={fallbackImg}
                    preview={false}
                  />
                </div>
                <div className="product-details-wrapper orders-details-wrapper">
                  <div className="product-info">
                    <h6 className="product-name">
                      {item?.productName || item?.name}
                    </h6>
                    <span className="product-count">
                      x {item?.quantity}
                      {/* x {Math.trunc(item?.quantity)} */}
                    </span>
                  </div>

                  <div className="price-holder">
                    <p className="price-after">
                      <span>{item?.price}</span>{' '}
                      <span>{localization.AED[currentLang]}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
          <div className="order-comment">
            <Form ref={formUserRef}>
              <Form.Item
                label={localization.comment[currentLang]}
                name="Comment"
                className="input-holder group-floating-label no-icon order-input-label"
              >
                <Input className="input-control" disabled />
              </Form.Item>
            </Form>
            {!orders?.cancelled && (
              <div className="order-comment-delivered">
                <img src={truckLine} alt="truck-icon" />
                <p>{localization.orderTime[currentLang]}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="single-order-price-info">
        <div className="single-order-price-info-top">
          <p className="single-order-info-container">
            <span className="single-order-info-item">
              {localization.Subtotal[currentLang]}
            </span>
            <span className="single-order-info-item__green">
              {parseInt(orders?.subtotal || 0).toFixed(2)}
            </span>
          </p>

          <p className="single-order-info-container">
            <span className="single-order-info-item">
              {localization.Discount[currentLang]}
            </span>
            <span className="single-order-info-item__green">
              {parseInt(orders?.discountPercentage || 0).toFixed(2)} %
            </span>
          </p>

          <p className="single-order-info-container">
            <span className="single-order-info-item single-order-info-item__bold">
              {localization.TOTAL[currentLang]}{' '}
              <span className="single-order-vat">
                ({localization.PricesincludeVAT[currentLang]})
              </span>
            </span>
            <span className="single-order-info-item__green">
              {parseInt(orders?.total || 0).toFixed(2)}
            </span>
          </p>
        </div>
        <div className="single-order-info-footer">
          <p className="single-order-info-footer-container">
            <span className="single-order-info-item-title">
              {localization.branch[currentLang]}
            </span>
            <span className="single-order-info-item-subtitle">
              {orders?.branch}
            </span>
          </p>
          <p className="single-order-info-footer-container">
            <span className="single-order-info-item-title">
              {localization.Address[currentLang]}
            </span>
            <span className="single-order-info-item-subtitle">
              {orders?.address}
            </span>
          </p>
          <p className="single-order-info-footer-container">
            <span className="single-order-info-item-title">
              {localization.Payment[currentLang]}
            </span>
            <span className="single-order-info-item-subtitle">
              {orders?.paymentType}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default RenderOrder;
