import { presistDataTypes } from './presistData.type';

export const fetchPresist = presistData => {
  return {
    type: presistDataTypes.PRESIST_DATA,
    payload: presistData,
  };
};

export const fetchMyOrders = presistData => {
  return {
    type: presistDataTypes.FETCH_MY_ORDERS,
    payload: presistData,
  };
};
export const fetchPrescriptionOrder = presistData => {
  return {
    type: presistDataTypes.FETCH_PRESCRIPTION_ORDER,
    payload: presistData,
  };
};
