import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';

import lines from '../../assets/img/lines.svg';
import vegt from '../../assets/img/vegt.png';
import fruits from '../../assets/img/fruits.jpg';
import localization from '../../Localization/Localization.json';

import './TrendingBrand.scss';
import { fallbackImg } from '../../constants/constants';
import { Col, Row } from 'antd';

const TrendingBrand = ({ data, currentLang, title }) => {
  console.log('TrendingBrand  data', data);
  return (
    <div className="trending-brand-wrapper">
      {/* <div className="trending-bg-left">
        <div className="trending-lines">
          <img src={lines} alt="" />
        </div>
        <div className="trending-hero"> 
          <I18Link to={{ pathname: `/category/1/${data[0]?.id}/${data[0]?.name?.replace('/','').replace('%','')}` }}>
            <img src={data[0]?.image || fallbackImg} alt="" />
          </I18Link>
        </div>
        <div className="content-title">
          <h2>{data[0]?.name}</h2>
          <I18Link to={{ pathname: `/category/1/${data[0]?.id}/${data[0]?.name?.replace('/','').replace('%','')}` }}>
            {localization.showproducts[currentLang]}
          </I18Link>
        </div>
      </div> */}
      <div className="container">
        <h4 className="section-title">{title}</h4>

        <div className="trending-brand-content">
          <Row gutter={[20, 20]}>
            {data?.map(brand => (
              <Col key={brand.id} xs={12} sm={8} lg={6} xl={4}>
                <I18Link
                  to={{
                    pathname:
                      brand.name === 'Medication'
                        ? '/coming-soon'
                        : `/category/1/${brand.id}/${brand.name
                            ?.replace('/', '')
                            .replace('%', '')}`,
                  }}
                  className="brand-holder"
                >
                  <img src={brand.image || fallbackImg} alt={brand.name} />
                  <p className="brand-title">{brand.name}</p>
                </I18Link>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(TrendingBrand);
