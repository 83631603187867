import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import localization from '../../Localization/Localization.json';
import { Link as I18Link } from 'react-router-i18n';
import { connect } from 'react-redux';
import { Breadcrumb, Form, Input, Button, message, Row, Col } from 'antd';
import { PhoneOutlined, HomeOutlined } from '@ant-design/icons';
import './Contact.scss';
import {
  phoneValidationAR,
  phoneValidationEN,
} from '../../constants/constants';
import { ContactUs } from '../../network/auth.network';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';
import { Helmet } from 'react-helmet-async';
import meta from '../../Localization/meta.json';
import fb from '../../assets/img/fb.svg';
import tw from '../../assets/img/tw.svg';
import ins from '../../assets/img/in.svg';
import socialLinks from '../../constants/social-links';

const { TextArea } = Input;

class Contact extends Component {
  formAddressRef = React.createRef();
  state = {
    btnLoading: false,
    isvalidateCaptcha: false,
  };

  componentDidMount() {
    loadCaptchaEnginge(6);
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
  }

  onFinish = values => {
    if (!this.state.isvalidateCaptcha) {
      message.warning(
        localization.pleaseEnterCaptchaValue[this.props.currentLang]
      );
      return false;
    }
    this.setState({ btnLoading: true });
    let body = new FormData();
    for (const property in values) {
      body.append(`${property}`, values[property] || '');
    }
    ContactUs(
      body,
      res => {
        message.success(res.data.message);
        this.formAddressRef.current.resetFields();
        this.setState({ btnLoading: false });
      },
      err => {
        console.log(err);
        this.setState({ btnLoading: false });
      }
    );
  };

  doSubmit = () => {
    let user_captcha = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(user_captcha) == true) {
      this.setState({ isvalidateCaptcha: true });
      message.success(localization.CaptchaMatched[this.props.currentLang]);
      // loadCaptchaEnginge(6);
      // document.getElementById("user_captcha_input").value = "";
    } else {
      message.error(localization.CaptchaDoesNotMatch[this.props.currentLang]);
      this.setState({ isvalidateCaptcha: false });
      document.getElementById('user_captcha_input').value = '';
    }
  };

  render() {
    const { btnLoading } = this.state;
    const { currentLang } = this.props;

    return (
      <div className="about-page-wrapper">
        <Helmet>
          <title>{meta.KhairZamanContact[currentLang]}</title>
        </Helmet>
        <Header />
        <div className="container">
          {/* <div className="about-hero"></div> */}
          {/* <div className="about-contentx"> */}
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <I18Link to={{ pathname: '/' }}>
                {localization.home[currentLang]}
              </I18Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {localization.Contactus[currentLang]}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="contact-grid-wrapper">
            <Row gutter={[30, 30]}>
              <Col xs={24} lg={12}>
                <ul className="contact-info-list">
                  <li>
                    <div className="key">
                      <PhoneOutlined />
                      phone
                    </div>
                    <a href="tel:15672" className="val">
                      15672
                    </a>
                  </li>
                  <li>
                    <div className="key">
                      <HomeOutlined />
                      {socialLinks.office_1[currentLang]?.title}
                    </div>
                    <a
                      href={socialLinks.office_1.url}
                      className="val"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {socialLinks.office_1[currentLang]?.lable}
                    </a>
                  </li>
                  <li>
                    <div className="key">
                      <HomeOutlined />
                      {socialLinks.office_2[currentLang]?.title}
                    </div>
                    <a
                      href={socialLinks.office_2.url}
                      className="val"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {socialLinks.office_2[currentLang]?.lable}
                    </a>
                  </li>
                  <li className="social-wrapper">
                    <a
                      href={socialLinks?.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={fb} alt="" />
                    </a>
                    <a
                      href={socialLinks?.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ins} alt="" />
                    </a>
                  </li>
                </ul>
              </Col>
              <Col xs={24} lg={12}>
                <div className="auth-form-content">
                  <Form
                    name="basic"
                    ref={this.formAddressRef}
                    onFinish={this.onFinish}
                  >
                    <Form.Item
                      label={localization.name[currentLang]}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: `${localization.Pleaseinput[currentLang]} 
                  ${localization.name[currentLang]}`,
                        },
                      ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input className="input-control" />
                    </Form.Item>
                    <Form.Item
                      label={localization.Phonenumber[currentLang]}
                      name="mobile"
                      rules={
                        currentLang === 'en'
                          ? phoneValidationEN
                          : phoneValidationAR
                      }
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input className="input-control" />
                    </Form.Item>
                    <Form.Item
                      label={localization.EmailAddress[currentLang]}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: `${localization.Pleaseinput[currentLang]} ${localization.EmailAddress[currentLang]}`,
                        },
                        {
                          type: 'email',
                          message: localization.Emailvalid[currentLang],
                        },
                      ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input className="input-control" />
                    </Form.Item>

                    {/* <Form.Item
                      label={localization.CompanyName[currentLang]}
                      name="companyName"
                      // rules={[
                      //   { required: true, message: `${localization.Pleaseinput[currentLang]}
                      //   ${localization.CompanyName[currentLang]}` },
                      // ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input className="input-control" />
                    </Form.Item>
                    <Form.Item
                      label={localization.JobTitle[currentLang]}
                      name="jobTitle"
                      // rules={[
                      //   { required: true, message: `${localization.Pleaseinput[currentLang]}
                      //   ${localization.Lastname[currentLang]}` },
                      // ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input className="input-control" />
                    </Form.Item>
                    <Form.Item
                      label={localization.TelephoneNumber[currentLang]}
                      name="mobile"
                      // rules={[
                      //   { required: true, message: `${localization.Pleaseinput[currentLang]}
                      //   ${localization.Lastname[currentLang]}` },
                      // ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input type="number" className="input-control" />
                    </Form.Item>
                    <Form.Item
                      label={localization.fax[currentLang]}
                      name="fax"
                      // rules={[
                      //   { required: true, message: `${localization.Pleaseinput[currentLang]}
                      //   ${localization.Lastname[currentLang]}` },
                      // ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input type="number" className="input-control" />
                    </Form.Item>
                    <Form.Item
                      label={localization.country[currentLang]}
                      name="country"
                      // rules={[
                      //   { required: true, message: `${localization.Pleaseinput[currentLang]}
                      //   ${localization.Lastname[currentLang]}` },
                      // ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input className="input-control" />
                    </Form.Item> */}

                    <Form.Item
                      label={localization.message[currentLang]}
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: `${localization.Pleaseinput[currentLang]} 
                  ${localization.message[currentLang]}`,
                        },
                      ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <TextArea
                        autoSize={{ minRows: 4, maxRows: 4 }}
                        allowClear
                        className="input-control"
                      />
                    </Form.Item>

                    {/* <Form.Item
                      label={localization.Address[currentLang]}
                      name="address"
                      // rules={[
                      //   { required: true, message: `${localization.Pleaseinput[currentLang]}
                      //   ${localization.Lastname[currentLang]}` },
                      // ]}
                      className="input-holder group-floating-label no-icon"
                    >
                      <Input className="input-control" />
                    </Form.Item> */}

                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        block
                        loading={btnLoading}
                      >
                        {localization.Send[currentLang]}
                      </Button>
                    </Form.Item>
                    <div className="form-group-captcha">
                      <div className="CanvasTemplate">
                        <LoadCanvasTemplate />
                      </div>

                      <div className="Captcha-input">
                        <input
                          placeholder={
                            localization.EnterCaptchaValue[currentLang]
                          }
                          id="user_captcha_input"
                          name="user_captcha_input"
                          type="text"
                        ></input>

                        <div
                          className="Captcha-btn-submit"
                          onClick={() => this.doSubmit()}
                        >
                          {localization.submit[currentLang]}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
          {/* </div> */}
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(Contact);
