import React, { Component } from 'react';
import {
  Modal,
  Radio,
  Form,
  Input,
  Select,
  Space,
  Spin,
  message,
  Button,
} from 'antd';
import { getApi } from '../../network/API';
import { ListAreasEP } from '../../network/EndPoints';
import { connect } from 'react-redux';
import { setBranchUser, setListAreas } from '../../redux/user/user.action';
import { addUserBranch } from '../../network/home.network';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import localization from '../../Localization/Localization.json';
import { withRouter } from 'react-router-dom';

const { confirm } = Modal;

class BranchModal extends Component {
  formUserRef = React.createRef();

  state = {
    showModal: false,
    areaLoading: false,
    branchLoading: false,
    ListAreas: [],
    activeArea: [],
    ListAreaBranches: [],
  };

  closeModal = () => {
    this.setState({ showModal: false, ListAreaBranches: [], ListAreas: [] });
  };

  componentDidMount() {
    // if(!this.props.areas){
    this.setState({ areaLoading: true });
    getApi(
      ListAreasEP,
      res => {
        if (res.data.code === 200) {
          this.setState({ ListAreas: res.data.data.areas, areaLoading: false });
          this.props.dispatch(setListAreas(res.data.data.areas));
        }
      },
      err => {
        console.log(err);
        this.setState({ areaLoading: false });
      }
    );
    // }else{
    //   console.log(this.props.areas)
    //     this.setState({ListAreas : this.props.areas})
    // }
  }

  handelListAreaBranches = value => {
    this.setState({ branchLoading: true });
    getApi(
      `areas/${value}/branches`,
      res => {
        if (res.data.code === 200) {
          const branches = res.data.data.branches;
          console.log('branches', res.data.data.branches);
          if (branches?.length === 1) {
            this.onChangeBranches(branches[0].id);
            this.setState({
              // ListAreaBranches: branches,
              branchLoading: true,
            });
            return;
          }

          this.setState({
            ListAreaBranches: branches,
            branchLoading: false,
          });
        }
      },
      err => {
        this.setState({ branchLoading: false });
        console.log(err);
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.branchModalVisible !== this.state.showModal) {
      this.setState({ showModal: this.props.branchModalVisible });
      if (this.props.branchModalVisible) {
        this.setState({ areaLoading: true });
        getApi(
          ListAreasEP,
          res => {
            if (res.data.code === 200) {
              this.setState({
                ListAreas: res.data.data.areas,
                areaLoading: false,
              });
              this.props.dispatch(setListAreas(res.data.data.areas));
            }
          },
          err => {
            console.log(err);
            this.setState({ areaLoading: false });
          }
        );
      }
    }
  }

  onFinish = values => {
    console.log('values', values);
  };

  handleProvinceChange = value => {
    this.setState({ activeArea: [], ListAreaBranches: [] }, () => {
      const activeArea = this.state.ListAreas.filter(area => area.id === value);
      this.formUserRef.current.setFieldsValue({ Street: [] });
      this.setState({ activeArea });
    });
  };

  onSecondCityChange = value => {
    this.handelListAreaBranches(value);
  };

  onChangeBranches = value => {
    // this.handelListAreaBranches(value);

    let body = new FormData();
    // body.append('branchId', e.target.value);
    body.append('branchId', value);
    body.append('override', 1);
    if (this.props.cartItems.length === 0) {
      this.setState({ branchLoading: true });
      addUserBranch(
        body,
        res => {
          this.setState({ branchLoading: false });
          if (res.data.code === 200) {
            message.success(res.data.message);
            this.props.dispatch(setBranchUser(res.data.data.branch));
            setTimeout(() => {
              this.props.closeModal();
              this.setState({ ListAreaBranches: [] });
              if (
                this.props.location.pathname === '/' ||
                this.props.location.pathname === '/en/' ||
                this.props.location.pathname === '/ar/'
              ) {
                try {
                  this.props.fetchHomeDetails();
                } catch (error) {
                  window.location.reload();
                }
              }
              if (
                this.props.location.pathname.includes('/product') ||
                this.props.location.pathname.includes('/recipes') ||
                this.props.location.pathname.includes('/search') ||
                this.props.location.pathname.includes('/category') ||
                this.props.location.pathname.includes('/checkout') ||
                this.props.location.pathname.includes('/deal')
              ) {
                window.location.reload();
              }
            }, 300);
          }
        },
        error => {
          this.setState({ btnLoading: false });
          console.log(error);
        }
      );
    } else {
      confirm({
        title: localization.Someproductslost[this.props.currentLang],
        icon: <ExclamationCircleOutlined />,
        okText: localization.Ok[this.props.currentLang],
        cancelText: localization.Cancel[this.props.currentLang],
        onOk: () => {
          this.setState({ branchLoading: true });
          addUserBranch(
            body,
            res => {
              this.setState({ branchLoading: false });
              if (res.data.code === 200) {
                message.success(res.data.message);
                this.props.dispatch(setBranchUser(res.data.data.branch));
                setTimeout(() => {
                  this.props.closeModal();
                  this.setState({ ListAreaBranches: [] });
                  if (
                    this.props.location.pathname === '/' ||
                    this.props.location.pathname === '/en/' ||
                    this.props.location.pathname === '/ar/'
                  ) {
                    try {
                      this.props.fetchHomeDetails();
                    } catch (error) {
                      window.location.reload();
                    }
                  }
                  if (
                    this.props.location.pathname.includes('/product') ||
                    this.props.location.pathname.includes('/recipes') ||
                    this.props.location.pathname.includes('/search') ||
                    this.props.location.pathname.includes('/category') ||
                    this.props.location.pathname.includes('/checkout') ||
                    this.props.location.pathname.includes('/deal')
                  ) {
                    window.location.reload();
                  }
                }, 500);
              }
            },
            error => {
              this.setState({ btnLoading: false });
              console.log(error);
            }
          );
        },
        onCancel() {},
      });
    }
  };

  render() {
    const {
      showModal,
      areaLoading,
      ListAreas,
      activeArea,
      branchLoading,
      ListAreaBranches,
    } = this.state;
    return (
      <div className="branch-modal-wrapper">
        <Modal
          wrapClassName="branch-modal"
          onCancel={this.props.closeModal}
          title=""
          footer={null}
          open={showModal}
          destroyOnClose
        >
          <Form
            name="branchs"
            ref={this.formUserRef}
            onFinish={this.onFinish}
            layout="vertical"
            className="select-branch-form"
          >
            <Form.Item
              name="Area"
              label={localization.Selectyourarea[this.props.currentLang]}
              className="input-holder group-nofloating-label"
            >
              <Select
                placeholder={localization.Area[this.props.currentLang]}
                showSearch
                onChange={this.handleProvinceChange}
                size="large"
                loading={areaLoading}
              >
                {ListAreas.map(area => (
                  <Select.Option key={area.id} value={area.id}>
                    {area.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="Street"
              label={localization.chooseDistrict[this.props.currentLang]}
              className="input-holder group-nofloating-label"
            >
              <Select
                // placeholder={localization.Street[this.props.currentLang]}
                placeholder={
                  localization.chooseDistrict[this.props.currentLang]
                }
                onChange={this.onSecondCityChange}
                size="large"
              >
                {activeArea[0]?.childs?.map(area => (
                  <Select.Option key={area.id} value={area.id}>
                    {area.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item>
              <Button type="primary" size="large" block htmlType="submit">
                {localization.select[this.props.currentLang]}
              </Button>
            </Form.Item> */}
            <Spin
              tip={localization.Loading[this.props.currentLang]}
              spinning={branchLoading}
            >
              <Radio.Group
                onChange={({ target }) => {
                  console.log(target.value);
                  this.onChangeBranches(target.value);
                }}
              >
                <Space direction="vertical">
                  {ListAreaBranches.map(branch => (
                    <Radio value={branch.id}>{branch.address}</Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Spin>
          </Form>
        </Modal>
      </div>
    );
  }
}

const BranchModalWithRouter = withRouter(BranchModal);

const mapStateToProps = state => ({
  areas: state.user.areas,
  currentLang: state.user.currentLang,
  cartItems: state.cart.cartItems,
});

export default connect(mapStateToProps)(BranchModalWithRouter);
