import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
// import localization from "../../Localization/Localization.json";
import faqLocal from '../../Localization/faq.json';
// import { Collapse } from 'antd';
import './FAQS.scss';
// import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async';
import meta from '../../Localization/meta.json';
import TermsAr from './TermsAr';
import TermsEn from './TermsEn';
// const { Panel } = Collapse;

class Terms extends Component {
  componentDidMount() {
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
  }

  render() {
    const { currentLang } = this.props;
    return (
      <div className="faq-page-wrapper">
        <Helmet>
          <title>{meta.KhairZamanTerms[currentLang]}</title>
        </Helmet>
        <Header />
        <div className="container">
          <div className="terms-content">
            <div className="content">
              <div className="title-of-article">
                <h1>{faqLocal.TermsandConditions[currentLang]}</h1>
              </div>
              {currentLang === 'ar' ? (
                <TermsAr currentLang={currentLang} />
              ) : (
                <TermsEn currentLang={currentLang} />
              )}
            </div>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const TermsWithRouter = withRouter(Terms);
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(TermsWithRouter);
