import {
  Breadcrumb,
  Button,
  Image,
  Skeleton,
  Modal,
  message,
  Spin,
} from 'antd';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import { Form, Input } from 'antd';
import truckLine from '../../assets/pickup-car.png';
import { connect } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import { fallbackImg } from '../../constants/constants';
import localization from '../../Localization/Localization.json';
import { fetchSingleOrder } from '../../network/home.network';
import './SingleOrder.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CancellationReasons from '../../components/CancellationReasons/CancellationReasons';
import { Reorder } from '../../network/profile.network';
import RenderOrder from './RenderOrder';
import RenderPrescription from './RenderPrescription';
const { confirm } = Modal;

class SingleOrder extends Component {
  formUserRef = React.createRef();
  state = {
    orders: [],
    showReasonsModal: false,
    isLoading: false,
    orderType: null,
    searchParams: {},
  };

  componentDidMount() {
    const { match, location } = this.props;
    const searchParamsUrl = new URLSearchParams(location.search);
    const searchParams = Object.fromEntries([...searchParamsUrl]);
    this.setState({ searchParams, orderType: searchParams.type || 'order' });

    // console.log('SingleOrder  componentDidMount  searchParams:', searchParams);
    // console.log('SingleOrder  componentDidMount  this.props:', this.props);
    const body = document.querySelector('#root');
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
    this.setState({ isLoading: true });
    console.log('SingleOrder  componentDidMount  searchParams', searchParams);

    fetchSingleOrder(
      match.params.id,
      searchParams.type,
      res => {
        this.setState(
          {
            isLoading: false,
            orders: res.data.data[this.state.orderType],
          },
          () => {
            res.data.data[this.state.orderType]?.comment &&
              this.formUserRef?.current?.setFieldsValue({
                Comment: res.data.data[this.state.orderType]?.comment,
              });
          }
        );
      },
      err => {
        this.setState({ isLoading: false });
        console.log(err);
      }
    );
  }

  handelReorder = orderId => {
    confirm({
      title: localization.reorderheseorder[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        let body = new FormData();
        body.append('orderId', orderId);
        Reorder(
          orderId,
          body,
          res => {
            if (res.data.code === 200) {
              message.success(res.data.message);
              this.props.history.push(`/${this.props.currentLang}/cart`);
            }
          },
          error => {
            console.log(error);
            // this.setState({btnLoading : false})
          }
        );
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  render() {
    const { currentLang, match } = this.props;
    console.log('SingleOrder  render  this.props:', this.props);
    const {
      showReasonsModal,
      isLoading,
      orders,
      formUserRef,
      searchParams,
      orderType,
    } = this.state;
    console.log('SingleOrder  render  searchParams:', searchParams);
    return (
      <div className="single-order-page">
        <Header />
        <CancellationReasons
          closeModal={() => this.setState({ showReasonsModal: false })}
          showModalVisible={showReasonsModal}
          orderId={match.params.id}
          orderType={orderType}
          currentLang={currentLang}
          localization={localization}
        />
        <Spin spinning={isLoading}>
          <div className="container single-order-page-bg">
            <div className="single-order-breadcrumb">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <I18Link to={{ pathname: '/' }}>
                    {localization.home[currentLang]}
                  </I18Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <I18Link
                    to={{
                      pathname:
                        orderType === 'prescription'
                          ? `/profile/8/prescription-order`
                          : `/profile/3/ordrers`,
                    }}
                  >
                    {orderType === 'prescription'
                      ? localization.prescriptionOrder[currentLang]
                      : localization.Myordrers[currentLang]}
                  </I18Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>#{orders?.id}</Breadcrumb.Item>
              </Breadcrumb>
              {!!(orderType !== 'prescription' && orders?.status?.id === 5) && (
                <Button
                  size="large"
                  style={{ width: 120 }}
                  type="primary"
                  // ghost
                  // className="cancel-order-btn order-cancel-btn reorder-btn"
                  onClick={() => this.handelReorder(this.state.orders.id)}
                >
                  {localization.reorderBtn[currentLang]}
                </Button>
              )}
              {orders?.status?.id !== 3 &&
                orders?.status?.id !== 5 &&
                orders?.status?.id !== 4 &&
                !orders?.cancelled &&
                !isLoading && (
                  <Button
                    size="middle"
                    style={{ width: 120 }}
                    type="danger"
                    ghost
                    className="cancel-order-btn order-cancel-btn"
                    onClick={() => this.setState({ showReasonsModal: true })}
                  >
                    {localization.Cancelorder[currentLang]}
                  </Button>
                )}
            </div>
            {orderType === 'prescription' ? (
              <RenderPrescription
                currentLang={currentLang}
                isLoading={isLoading}
                orders={orders}
                formUserRef={formUserRef}
              />
            ) : (
              <RenderOrder
                currentLang={currentLang}
                isLoading={isLoading}
                orders={orders}
                formUserRef={formUserRef}
              />
            )}
          </div>
        </Spin>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const SingleOrderWithRouter = withRouter(SingleOrder);

const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(SingleOrderWithRouter);
