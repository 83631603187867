import React, { Component } from 'react';
import Slider from 'react-slick';
import './SliderSection.scss';
import ProductCard from '../ProductCard/ProductCard';
import right_arrow from '../../assets/img/right_arrow.svg';
import left_arrow from '../../assets/img/left_arrow.svg';
import { Skeleton } from 'antd';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';

const ITEMS_PER_SLIDER = 5;
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img src={right_arrow} alt='' className={className} onClick={onClick} />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img src={left_arrow} alt='' className={className} onClick={onClick} />
  );
}

export default class SliderSection extends Component {
  state = {
    sliderList: [],
    index: 0,
  };

  componentDidMount() {
    const { sliderList } = this.props;
    this.setState({ sliderList });
  }

  beforeChange = (prev, next) => {
    this.setState({ index: next });
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: ITEMS_PER_SLIDER,
      slidesToScroll: ITEMS_PER_SLIDER,
      // centerMode: true,
      // centerPadding: "70px",
      pauseOnHover: true,
      arrows: true,
      nextArrow: (
        <LeftArrow
          isDisabled={
            this.state.index === this.state.sliderList.length - ITEMS_PER_SLIDER
          }
        />
      ),
      prevArrow: <RightArrow isDisabled={this.state.index === 0} />,
      beforeChange: this.beforeChange,
      responsive: [
        {
          breakpoint: 1367,
          settings: {
            // centerPadding: "90px",
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '70px',
            centerMode: true,
            autoplay: true,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: true,
            centerPadding: '40px',
            autoplay: true,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: true,
            infinite: true,
            centerPadding: '60px',
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            speed: 400,
            autoplaySpeed: 400,
          },
        },
      ],
    };
    const { title, shapeLoading } = this.props;
    const { sliderList } = this.props;
    return (
      <div className='slider-section-wrapper'>
        <div className='container'>
          <h4 className='section-title'>{title}</h4>
          <div className='slider-section-content'>
            <Slider {...settings}>
              {sliderList.map((card, idx) => (
                <div className='slide-holder' key={idx}>
                  {shapeLoading ? (
                    <Skeleton
                      block
                      avatar
                      size='large'
                      avatarShape='square'
                      active
                      paragraph={{ rows: 4 }}
                    />
                  ) : (
                    <ProductCard card={card} />
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
