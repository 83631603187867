import { css } from '@emotion/css';
import { message, Spin } from 'antd';
// import useSearchQuery from 'hooks/useSearchQuery';
// import { t } from 'i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import localization from '../../Localization/Localization.json';

function CheckoutRedirect() {
  const CheckoutRedirectStyles = css`
    min-height: 82vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .message-wrapper {
      width: 800px;
      max-width: 100%;
      /* border: 1px solid #eee; */
      /* background-color: #222; */
      padding: 40px 60px;
      border-radius: 10px;
      box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.2);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      font-size: 16px;
      text-align: center;
      line-height: 2;
      color: #333;

      .title {
        font-size: 20px;
        font-weight: 600;
      }
    }
  `;
  // const { searchQueryObj } = useSearchQuery();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const searchParamsUrl = new URLSearchParams(location.search);
  const searchParams = Object.fromEntries([...searchParamsUrl]);
  console.log('CheckoutRedirect  searchParams:', searchParams);
  console.log('CheckoutRedirect  params:', params);
  const { currentLang } = useSelector(s => s.user);
  // const isSuccess = searchQueryObj.success !== 'false';
  // console.log('CheckoutRedirect  isSuccess:', isSuccess);

  const wait = callback => setTimeout(callback, 2000);

  useEffect(() => {
    wait(() => {
      if (searchParams?.success)
        history.push({
          pathname: `/${params.locale || 'en'}/order-placed`,
          // state: { orderID: res.data.item },
        });
      else {
        history.push(`/${params.locale || 'en'}/checkout`);
        message.error(localization.paymentFailed[params.locale || 'en']);
      }
    });
  }, [searchParams?.success, history, params.locale]);
  return (
    <div className={CheckoutRedirectStyles}>
      <div className="message-wrapper">
        <div className="title">
          {localization.redirectedInMoment[params.locale || currentLang]}
        </div>
        <Spin />
        <span>
          {localization.dontCloseThisPage[params.locale || currentLang]}
        </span>
      </div>
    </div>
  );
}

export default CheckoutRedirect;
