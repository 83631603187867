import { message } from "antd";
import axios from "axios";
const url = process.env.REACT_APP_API_KEY;


export const getLvl1Categories = (id, lvl, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
    method: "get",
    url: `${url}categories/show/${id}/level${lvl}`, 
    // data: body,
    // headers: myHeaders, 
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const getCategoryProducts = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
    method: "post",
    url: `${url}category/products`, 
    data: body,
    // headers: myHeaders, 
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const addProductUserCart = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
    method: "post",
    url: `${url}cart/add`,
    data: body, 
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          // message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const updateProductUserCart = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
    method: "post",
    url: `${url}cart/update`,
    data: body,
    headers, 
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};


export const removeProductUserCart = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
    method: "post",
    url: `${url}cart/delete`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const favProductUserCart = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
    method: "post",
    url: `${url}favorite/toggle/product`,
    data: body,
    headers,
  })
    .then((response) => { 
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const fetchCart = (onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}` 
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}cart/view`,
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                if(response.data.code !== 101){
                  // message.error(element);
                }
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const fetchFilterData = (onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}products/filters`,
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const fetchAltsProduct = (id, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}products/${id}/alternatives`,
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const fetchBrand = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}products/filter?${body}`,
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const fetchLvl1Products = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}products/filter?${body}`,
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  // message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const fetchSearch = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}products/filter?keyword=${body}`,
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  // message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const searchFilterationData = (body, onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}products/search?keyword=${body}`, 
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  // message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const dealFilterationData = (onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}products/search?deal=${1}`, 
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  // message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const fetchSubscriptionTypes = (onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}subscription/types`,
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}

export const fetchProductInfo = (id ,onSucess, onFail) => {
      const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
      method: 'get',
      url: `${url}products/${id}`,
      headers,
      })
      .then((response) => {
          if (response.data.validation.length > 0) { 
              response.data.validation.forEach(element => {
                  message.error(element); 
              });
          }
          return onSucess(response);
      }) 
      .catch(err => onFail(err)); 
}
