import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import '../../scss/pages/auth.scss';
import { forgetPassword } from '../../network/auth.network';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.action';
import {
  phoneValidationAR,
  phoneValidationEN,
} from '../../constants/constants';
import { clearCart } from '../../redux/cart/cart.action';
import localization from '../../Localization/Localization.json';
// import logo from '../../assets/img/Logo.png';
import logo from '../../assets/img/logogreen-croped.png';

import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import meta from '../../Localization/meta.json';
class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      isLoginValid: false,
    };
  }

  onFinish = values => {
    this.setState({ btnLoading: true });
    let body = new FormData();
    // body.append("user_id", this.props.location?.state.user_id);
    body.append('phone', values.phone);
    // if(values.phone){
    //   body.append("phone", values.phone);
    // }else if(values.email){
    //   body.append("email", values.email);
    // }
    forgetPassword(
      body,
      res => {
        this.setState({ btnLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          setTimeout(() => {
            this.props.history.push({
              pathname: `/${this.props.currentLang}/reset-password`,
              state: { user_id: res.data.item },
            });
          }, 400);
          // setTimeout(() => {
          //     this.props.history.push("/reset-password");
          //   }, 400)
        }
      },
      error => {
        console.log(error);
        this.setState({ btnLoading: false });
      }
    );
  };

  render() {
    const { btnLoading } = this.state;
    const { currentLang } = this.props;
    return (
      <div>
        <Helmet>
          <title>{meta.KhairZamanForget[currentLang]}</title>
        </Helmet>
        <div className="auth-wrapper">
          <div className="auth-form-content">
            <I18Link to={{ pathname: '/' }} className="logo-holder">
              <img src={logo} alt="logo" className="typ-new-logo" />
            </I18Link>
            <p className="form-welcom">
              {localization.Forgetpassword[currentLang]}
            </p>
            {/* <p className="login-text">{localization.enterEmailRest[currentLang]}</p> */}
            <p className="login-text">
              {localization.enterPhoneRest[currentLang]}
            </p>
            <Form name="basic" onFinish={this.onFinish}>
              <Form.Item
                label={localization.Phonenumber[currentLang]}
                name="phone"
                rules={
                  currentLang === 'en' ? phoneValidationEN : phoneValidationAR
                }
                className="input-holder group-floating-label"
              >
                <Input
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  className="input-control"
                />
              </Form.Item>
              {/* <Form.Item
                label={localization.EmailAddress[currentLang]} 
                name="email"
                rules={[
                  { required: true, message: `${localization.Pleaseinput[currentLang]} ${localization.EmailAddress[currentLang]}` },
                ]}
                className="input-holder group-floating-label" 
              >
                <Input prefix={<MailOutlined className="site-form-item-icon" />} className="input-control"/>
              </Form.Item> */}

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  loading={btnLoading}
                >
                  {localization.Send[currentLang]}
                </Button>
              </Form.Item>
              <p className="user-text">
                <I18Link to={{ pathname: '/login' }}>
                  {' '}
                  {localization.backtologin[currentLang]}
                </I18Link>
              </p>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});
const PhoneVerifyWithRouter = withRouter(ForgetPassword);
export default connect(mapStateToProps)(PhoneVerifyWithRouter);
