import { Breadcrumb, Pagination, Skeleton } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import {
  fetchOffers,
  fetchOffersType,
  fetchRecipes,
} from '../../network/home.network';
import localization from '../../Localization/Localization.json';
import './Recipes.scss';
import {Helmet} from "react-helmet-async";
import meta from '../../Localization/meta.json';

class Recipes extends Component {
  state = {
    recipesList: [],
    offerTypes: null,
    loading: true,
    isOfferSection: false,
  };

  componentDidMount(prevProps, prevState) {
    const { match } = this.props;
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);
    // if(match?.params?.id){
    //     let body = new FormData();
    //     body.append("offer_type_id", match.params.id);
    //     fetchOffersType(body, (res) => {
    //         console.log(res.data.data);
    //         this.setState({
    //             offerList : res.data.data.products,
    //             pagination : res.data.data.pagination,
    //             loading : false})
    //     }, (error) => {
    //         this.setState({loading : false})
    //     })
    // }else{
    //     fetchOffers(false, (res) => {
    //         this.setState({ offerList : res.data.data.products, pagination : res.data.data.pagination,  offerTypes : res.data.data.offerTypes, loading : false})
    //     }, (error) => {
    //         this.setState({loading : false})
    //     })
    // }

    // axios
    //   .get("https://www.themealdb.com/api/json/v1/1/search.php?f=c")
    //   .then((res) => {
    //       const list = res.data.meals;
    //       console.log(list)
    //       this.setState({ recipesList : list, loading : false}, () => {

    //           console.log(this.state)
    //       });
    //   });

    fetchRecipes(
      (res) => {
        this.setState({
          recipesList: res.data.data.recipes,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentLang } = this.props;
    if (currentLang !== prevProps.currentLang) {
      this.setState({ loading: true });
      fetchRecipes(
        (res) => {
          this.setState({
            recipesList: res.data.data.recipes,
            loading: false,
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    }
  }

  onChangePagination = (page) => {
    const { match } = this.props;
    this.setState({ loading: true });
    if (match?.params?.id) {
      let body = new FormData();
      body.append('offer_type_id', match.params.id);
      if (this.state.pagination?.totalPages > 1 && page) {
        body.append('page', page);
      }
      fetchOffersType(
        body,
        (res) => {
          this.setState({
            offerList: res.data.data.products,
            pagination: res.data.data.pagination,
            loading: false,
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    } else {
      let params = '';
      if (this.state.pagination?.totalPages > 1 && page) {
        params += `?page=${page}`;
      }
      fetchOffers(
        params,
        (res) => {
          this.setState({
            offerList: res.data.data.products,
            pagination: res.data.data.pagination,
            offerTypes: res.data.data.offerTypes,
            loading: false,
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    }
  };

  render() {
    const { currentLang } = this.props;
    const { offerList, loading, pagination, offerTypes, recipesList } =
      this.state;
    return (
      <div className="recipes-page-wrapper">
        <Helmet>
            <title>{meta.KhairZamanRecipes[currentLang]}</title>
        </Helmet>
        <Header />
        <div className="container">
          <div className="recipes-hero">
            {/* <h1>{localization.Recipes[currentLang]}</h1> */}
          </div>
          <div className="recipes-content">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <I18Link to={{ pathname: '/' }}>
                  {localization.home[currentLang]}
                </I18Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {localization.Recipes[currentLang]}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="recipes-grid-wrapper">
              {!loading
                ? recipesList.map((card) => (
                    <div className="recipes-card-holder">
                      <I18Link to={{ pathname: `/recipes/${card.id}` }}>
                        {/* to={`/recipe/${card.id}/${card.strMeal}`} */}
                        <div className="recipes-card-img">
                          <img src={card.image} alt="" />
                        </div>
                        <div className="recipes-card-content">
                          <h4 className="recipes-card--title">{card.name}</h4>
                          {/* <p className="recipes-card--text">
                            {card.description.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ')}
                          </p> */}
                        </div>
                      </I18Link>
                    </div>
                  ))
                : [0, 0, 0, 0, 0, 0, 0, 0].map((skl, idx) => (
                    <Skeleton key={idx} avatar active paragraph={{ rows: 4 }} />
                  ))}
            </div>
          </div>
          {/* {pagination && pagination?.totalPages > 1 && 
                <div className="pagination-wrapper">     
                  <Pagination responsive 
                  onChange={this.onChangePagination}
                  current={pagination?.current} pageSize={pagination?.size} total={pagination?.total} /> 
                </div>
                } */}
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const RecipesWithRouter = withRouter(Recipes);
const mapStateToProps = (state) => ({
  //   productsList: state.products.productsList.slice(200, 230),
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(RecipesWithRouter);
