import { message } from 'antd';
import axios from 'axios';
const url = process.env.REACT_APP_API_KEY;

const getHeader = () => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
    if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    }
  }

  return headers;
};

export const applyPromoCode = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}cart/promoCode/add`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const applyRemovePromoCode = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}cart/promoCode/remove`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const GenerateOrderSession = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}cart/generate/order/session`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const applycomment = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}cart/comment`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const SelectSubscription = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}cart/subscription/update`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const cancelCartSubscription = (onSucess, onFail) => {
  axios({
    method: 'get',
    url: `${url}cart/subscription/cancel`,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const OrderSubscription = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}order/subscribe`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const OrderUnSubscription = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}order/unsubscribe`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const PlaceOrder = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}cart/order`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const SelectPayment = (body, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}cart/payment/select`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const SelectOrderAlternatives = (body, id, onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}orders/${id}/alternatives`,
    data: body,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const fetchPaymentList = (onSucess, onFail) => {
  axios({
    method: 'get',
    url: `${url}cart/payment/types`,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const fetchReasonsList = (onSucess, onFail) => {
  axios({
    method: 'get',
    url: `${url}list/cancellation/reasons`,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};
export const ShowOrderAlternatives = (id, onSucess, onFail) => {
  axios({
    method: 'get',
    url: `${url}orders/${id}/alternatives`,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const EmptyCart = (onSucess, onFail) => {
  axios({
    method: 'post',
    url: `${url}cart/empty`,
    headers: getHeader(),
  })
    .then(response => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach(element => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch(err => onFail(err));
};

export const getPayOnlineUrl = async () => {
  try {
    const res = await axios.get(`${url}payment/pay`, {
      headers: getHeader(),
    });
    // console.log('getPayOnlineUrl  res:', res);
    return res.data?.data?.['IFrame URL'];
  } catch (error) {
    // console.log('getPayOnlineUrl  error:', error);
  }
};
