import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { setCategories } from './redux/user/user.action';

//
// import { css, injectGlobal } from '@emotion/css';
// import landImg from './assets/img/LANDINGPAGE.jpeg';
// import fb from './assets/img/fb.svg';
// import ins from './assets/img/in.svg';

// process.env.NODE_ENV === "production" &&
Sentry.init({
  dsn: 'https://daf5767ef320424ba4209f1303e0feb5@o1099583.ingest.sentry.io/6124303',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function AppWrapper() {
  const dispatch = useDispatch();
  const onBeforeLift = () => {
    dispatch(setCategories(null));
    // alert('onBeforeLift');
  };
  return (
    <PersistGate
      loading={null}
      persistor={persistor}
      onBeforeLift={onBeforeLift}
    >
      <App />
    </PersistGate>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);
// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <React.StrictMode>
//         <PersistGate
//           loading={null}
//           persistor={persistor}
//           onBeforeLift={() => {

//           }}
//         >
//           <App />
//         </PersistGate>
//       </React.StrictMode>
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById('root')
// );

// FOR LANDING PAGE
// const AppStyles = css`
//   width: 100vw;
//   height: 100vh;
//   position: relative;

//   .bg-img {
//     width: 100%;
//     height: 100%;
//     background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
//       url(${landImg});
//     background-size: cover;
//     /* background-position: 50% 50%; */
//     filter: blur(8px);
//   }

//   .content-wrapper {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 2;
//     width: 1000px;
//     max-width: 100%;
//     img {
//       width: 100%;
//       height: 100%;
//       object-fit: contain;
//     }

//     .social-wrapper {
//       position: relative;
//       left: 0;
//       bottom: -10px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 100%;
//       gap: 15px;

//       a {
//         img {
//           width: 24px;
//         }
//       }

//       @media screen and (min-width: 650px) {
//         position: absolute;
//         left: 20px;
//         bottom: 3px;
//         justify-content: flex-start;
//       }
//     }
//   }
// `;

// injectGlobal`
// body {
// margin: 0 !important;
// }
// `;
// ReactDOM.render(
//   <div className={AppStyles}>
//     <div className="bg-img"></div>
//     <div className="content-wrapper">
//       <img src={landImg} alt="Elzoghbi pharmacy" />
//       <ul className="social-wrapper">
//         <li>
//           <a
//             href="https://www.facebook.com/AlZoghbiPharm/"
//             target="_blank"
//             rel="noreferrer"
//           >
//             <img src={fb} alt="" />
//           </a>
//         </li>
//         <li>
//           <a
//             href="https://instagram.com/alzoghbipharmacy?igshid=MWI4MTIyMDE="
//             target="_blank"
//             rel="noreferrer"
//           >
//             <img src={ins} alt="" />
//           </a>
//         </li>
//       </ul>
//     </div>
//   </div>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
