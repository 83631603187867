import { css } from '@emotion/css';
import { Button, Dropdown, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';

// FOR STYLE UTILS
const menuDimensions = {
  width: '220px',
  overflow: 'auto',
};

function CategoriesMenu({ loading }) {
  const { categories, currentLang } = useSelector(s => s?.user);
  // console.log('CategoriesMenu  categories', categories);

  const CategoriesMenuStyles = css`
    margin-inline-end: auto;
    /* display: flex;
    gap: 10px; */

    /* background-color: red; */

    .dropdown-wrapper {
      button {
        padding: 4px 10px;
      }
    }

    .ant-skeleton-element {
      display: inline-block;
      height: 30px;
      padding: 0px;
      margin: 0 4px;
      .ant-skeleton-input {
        min-width: auto;
        width: 100px;
      }
    }
    @media only screen and (max-width: 900px) {
      display: none;
    }

    .coming-soon-link {
      display: inline-block;
      padding: 5px;
      font-size: 14px;
      text-transform: capitalize;
    }
  `;
  const menuStyle = css`
    /* box-shadow: none !important; */
    /* background-color: #e06969; */

    .ant-dropdown-menu-submenu-title {
      display: flex;
      align-items: center;
    }
    .ant-dropdown-menu-title-content {
      max-inline-size: 26ch;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
  `;

  const history = useHistory();
  // // console.log('CategoriesMenu  categoryIndex', categoryIndex);

  const getMenuItems = item => {
    const key = item?.id + '/' + item?.name;
    const hasChildren = !!item?.children?.length;

    return {
      key,
      label: item?.name,
      children: hasChildren && item?.children?.map(sub => getMenuItems(sub)),
      // onTitleClick: ({ key }) => navigate(key),
      // onClick: ({ key }) => navigate(key),
      onTitleClick: ({ key }) => {
        if (hasChildren) {
          // // console.log('onTitleClick  item.level', item.level);
          history.push(`/${currentLang}/category/${item.level}/${key}`);
        }
      },
      onClick: ({ key }) => {
        if (hasChildren) return;
        // // console.log('onClick  item.level', item.level);
        history.push(`/${currentLang}/category/${item.level}/${key}`);
      },
    };
  };

  return (
    <div className={CategoriesMenuStyles}>
      {loading
        ? Array(6)
            .fill(0)
            .map(s => <Skeleton.Input />)
        : categories?.map(cat => {
            if (cat?.id === 10)
              return (
                <I18Link to={'/coming-soon'} className="coming-soon-link">
                  {cat?.name}
                </I18Link>
              );

            return (
              <Dropdown
                menu={{
                  items: cat?.children?.map(subCat =>
                    getMenuItems(subCat, '/categories')
                  ),
                  // subMenuCloseDelay: 0.2,

                  className: menuStyle,
                  style: {
                    ...menuDimensions,
                  },
                }}
                arrow
                className="dropdown-wrapper"
              >
                <Link
                  to={`/${currentLang}/category/${cat.level}/${cat?.id}/${cat?.name}`}
                >
                  <Button type="link">{cat?.name}</Button>
                </Link>
              </Dropdown>
            );
          })}
    </div>
  );
}

export default CategoriesMenu;
