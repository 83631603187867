import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link as I18Link } from 'react-router-i18n';
import google from '../../assets/img/google.svg';
import apple from '../../assets/img/apple.svg';
import fb from '../../assets/img/fb.svg';
import tw from '../../assets/img/tw.svg';
import ins from '../../assets/img/in.svg';
// import logo from '../../assets/img/Logo.png';
import logo from '../../assets/img/logogreen-croped.png';

import MANSOUR from '../../assets/img/MANSOUR-logo.jpg';
import localization from '../../Localization/Localization.json';
import BranchModal from '../BranchModal/BranchModal';
import './Footer.scss';
import socialLinks from '../../constants/social-links';
import { Tooltip } from 'antd';

class Footer extends Component {
  state = {
    branchModalVisible: false,
  };
  render() {
    const { currentLang } = this.props;
    const { branchModalVisible } = this.state;
    return (
      <div className="footer-wrapper">
        <BranchModal
          fetchHomeDetails={this.props.fetchHomeDetails}
          closeModal={() => this.setState({ branchModalVisible: false })}
          branchModalVisible={branchModalVisible}
        />
        <div className="container">
          <div className="footer-content">
            <div className="links-wrapper">
              <div className="links-group logos-web-holder">
                <I18Link to={{ pathname: '/' }}>
                  <img src={logo} alt="" className="typ-new-logo" />
                </I18Link>
                {/* <a className="MANSOUR-logo">
                <img src={MANSOUR} alt="" />
            </a> */}
              </div>
              <div className="links-group">
                <h4>{localization.About[currentLang]}</h4>
                <p>
                  {localization.alzoghbiPharmacy[currentLang]}{' '}
                  {localization.aboutSec1[currentLang]}
                </p>
                <p>
                  {localization.alzoghbiPharmacy[currentLang]}{' '}
                  {localization.aboutSec2[currentLang]}
                </p>
                <p>
                  {localization.alzoghbiPharmacy[currentLang]}{' '}
                  {localization.aboutSec3[currentLang]}
                </p>
                <div className="follow-wrapper">
                  <p className="follow-title">
                    {localization.followUs[currentLang]}
                  </p>
                  <ul>
                    <li>
                      <a
                        href={socialLinks?.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={fb} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={socialLinks?.instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={ins} alt="" />
                      </a>
                    </li>
                    {/* <li>
                    <a href="https://twitter.com/kheirzaman" target="_blank">
                      <img src={tw} alt="" />
                    </a>
                  </li> */}
                  </ul>
                </div>
              </div>

              <div className="links-group">
                <h4>{localization.Quicklinks[currentLang]}</h4>
                <ul>
                  {/* <li>
                  <Link to="/">{localization.privacyPolicy[currentLang]}</Link>
                </li> */}
                  <li>
                    <I18Link to={{ pathname: '/terms-and-conditions' }}>
                      {localization.TermsConditions[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: '/contact-us' }}>
                      {localization.Contactus[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: '/FAQs' }}>
                      {localization.FAQs[currentLang]}
                    </I18Link>
                  </li>
                </ul>
              </div>
              {/* <div className="links-group">
                <h4>{localization.Downloadapp[currentLang]}</h4>
                <ul>
                  <li>
                    <Tooltip title="Soon">
                      <a href="" rel="noreferrer" target="_blank">
                        <img src={apple} alt="apple" />
                      </a>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title="Soon">
                      <a href="" rel="noreferrer" target="_blank">
                        <img src={google} alt="google" />
                      </a>
                    </Tooltip>
                  </li>
                </ul>
              </div> */}
            </div>

            <div className="all-rights-wrraper">
              <span className="copy-r">
                &copy; {new Date().getFullYear()}.{' '}
                {localization.rights[currentLang]}
              </span>
              {/* <span className="copy-r">
              {localization.TaxRegistration[currentLang]} 
            </span> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(Footer);
