import React, { Component } from 'react';
import { Link as I18Link } from 'react-router-i18n';
import { withRouter } from 'react-router-dom';
import {
  Menu,
  Dropdown,
  Button,
  AutoComplete,
  Input,
  Badge,
  // Tooltip,
  Drawer,
} from 'antd';
import {
  AlignLeftOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  FileTextOutlined,
  ImportOutlined,
  CaretDownOutlined,
  SearchOutlined,
  LogoutOutlined,
  EnvironmentOutlined,
  HeartOutlined,
  PhoneOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import './Header.scss';
import { connect } from 'react-redux';
import {
  setCurrentUser,
  setCurrentLang,
  setAddressUser,
  setPaymentUser,
  setBrandsList,
  setCategories,
  setBranchUser,
} from '../../redux/user/user.action';
import { clearCart } from '../../redux/cart/cart.action';
import localization from '../../Localization/Localization.json';
import logoUpd from '../../assets/img/logogreen-croped.png';
// import location_icon from '../../assets/img/loc-white.svg';
import black_user from '../../assets/img/black_user.svg';
import black_cart from '../../assets/img/black_cart.svg';
import favIcon from '../../assets/img/no_fav.svg';
import BranchModal from '../BranchModal/BranchModal';
import { getApi } from '../../network/API';
import { LevelsTreeEP } from '../../network/EndPoints';
import CategoriesMenu from './CategoriesMenu/CategoriesMenu';
const { SubMenu } = Menu;
const stripLocale = (pathname, locale) => {
  if (!locale) {
    return pathname;
  }
  return pathname.replace(`/${locale}`, '');
};

class Header extends Component {
  state = {
    showSearch: false,
    showModalVisible: false,
    branchModalVisible: false,
    categoryVisible: false,
    drawerVisible: false,
    categoryLoading: false,
    categoriesList: null,
    MoreIpad: window.matchMedia('(min-width: 992px)').matches,
  };

  componentDidMount() {
    this.fetchCategoryList();
    const handler = e => this.setState({ MoreIpad: e.matches }, () => {});
    window.matchMedia('(min-width: 992px)').addListener(handler);
  }

  handelMenuSelect = val => {
    if (val.key === '6') {
      localStorage.removeItem('token');
      this.props.dispatch(setCurrentUser(null));
      this.props.dispatch(setAddressUser(null));
      this.props.dispatch(setPaymentUser(null));
      this.props.dispatch(setBranchUser(null));
      this.props.dispatch(clearCart());
      if (
        this.props.location.pathname.includes('/profile') ||
        this.props.location.pathname.includes('/checkout')
      ) {
        this.props.history.push(`/${this.props.currentLang}/`);
      }
      if (this.props.location.pathname.includes('/cart')) {
        setTimeout(() => {
          window.location.reload();
        }, 400);
      }
      if (
        this.props.location.pathname === '/' ||
        this.props.location.pathname === '/en/' ||
        this.props.location.pathname === '/ar/'
      ) {
        setTimeout(() => {
          try {
            this.props?.fetchHomeDetails();
          } catch (error) {
            window.location.reload();
          }
        }, 200);
      }
    }
  };

  handelLogOut = () => {
    localStorage.removeItem('token');
    this.props.dispatch(setCurrentUser(null));
    this.props.dispatch(setAddressUser(null));
    this.props.dispatch(setPaymentUser(null));
    this.props.dispatch(setBranchUser(null));
    this.props.dispatch(clearCart());
    if (
      this.props.location.pathname.includes('/profile') ||
      this.props.location.pathname.includes('/checkout')
    ) {
      this.props.history.push(`/${this.props.currentLang}/`);
    }
    if (this.props.location.pathname.includes('/cart')) {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    }
    if (
      this.props.location.pathname === '/' ||
      this.props.location.pathname === '/en/' ||
      this.props.location.pathname === '/ar/'
    ) {
      setTimeout(() => {
        try {
          this.props?.fetchHomeDetails();
        } catch (error) {
          window.location.reload();
        }
      }, 200);
    }
    document
      .getElementsByClassName('main-header-bottom')[0]
      .classList.remove('active');
  };

  changeLang = lang => {
    this.setState({ categoryVisible: false });
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    localStorage.setItem('lang', lang);
    this.props.dispatch(setCurrentLang(lang));
    this.props.dispatch(setBrandsList([]));
    this.props.dispatch(setBranchUser(null));
    this.props.dispatch(setCategories(null));
    document
      .getElementsByClassName('category-menu-dropdown')[0]
      ?.classList.remove('active');
    window.location.reload();
  };

  handelOpenMenu = () => {
    this.setState({ drawerVisible: !this.state.drawerVisible });
  };
  onSearchInput = value => {
    this.setState({ searchVal: value });
  };

  onSearch = (e, val = false) => {
    const value = val ? val : e.target.value;
    if (!!value) {
      this.props.history.replace(`/${this.props.currentLang}/search/${value}`);
      this.props.history.push(`/${this.props.currentLang}/search/${value}`);
    }
  };

  handelCategoryList = async () => {
    if (!this.props.categories) {
      this.setState({ categoryLoading: true });
      await this.fetchCategoryList();
      setTimeout(() => {
        this.toggleCategoriesWithClass();
      }, 2000);
    } else {
      this.setState({ categoriesList: this.props.categories });
      this.toggleCategoriesWithClass();
    }
  };

  toggleCategoriesWithClass = () => {
    document
      .getElementsByClassName('category-menu-dropdown')[0]
      .classList.toggle('active');
    this.setState({ categoryVisible: !this.state.categoryVisible });
  };

  fetchCategoryList = () => {
    const categoriesList = this.props.categories;
    if (categoriesList) return this.setState({ categoriesList });

    this.setState({ categoryLoading: true });
    getApi(
      LevelsTreeEP,
      res => {
        this.setState({ categoryLoading: false });
        if (res.data.code === 200) {
          this.setState({
            categoriesList: res.data.data.categories,
            categoryLoading: false,
          });
          this.props.dispatch(setCategories(res.data.data.categories));
        }
      },
      err => {
        this.setState({ categoryLoading: false });
      }
    );
  };

  render() {
    const {
      MoreIpad,
      showModalVisible,
      branchModalVisible,
      categoriesList,
      categoryVisible,
      drawerVisible,
      categoryLoading,
    } = this.state;
    const { currentUser, currentLang, currentBranch } = this.props;
    const {
      location: { pathname },
      match: {
        params: { locale },
      },
      history,
    } = this.props;
    // console.log(MoreIpad);
    return (
      <>
        <div className="header-top-wrapper">
          <div className="container">
            <div className="header-top-content">
              <div className="links-list-wrapper">
                <ul>
                  <li>
                    <I18Link to={{ pathname: '/deals' }}>
                      <span className="animate-offers">
                        {localization.Deals[currentLang]}
                      </span>
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: '/about' }}>
                      {localization.About[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: '/contact-us' }}>
                      {localization.Contactus[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: '/FAQs' }}>
                      {localization.FAQs[currentLang]}
                    </I18Link>
                  </li>
                </ul>
              </div>

              <div className="header-top-center-wrapper">
                <div className="search-input-holder">
                  <AutoComplete onSearch={this.onSearchInput}>
                    <Input
                      size="large"
                      placeholder={localization.Search[currentLang]}
                      onPressEnter={this.onSearch}
                      suffix={
                        <SearchOutlined
                          onClick={e => this.onSearch(e, this.state.searchVal)}
                        />
                      }
                    />
                  </AutoComplete>
                </div>
                {/* <Button
                  type="default"
                  className="branch-btn"
                  onClick={() => this.setState({ branchModalVisible: true })}
                >
                  <img src={location_icon} alt="" />
                  {currentBranch ? (
                    <Tooltip title={currentBranch.address}>
                      <p>{currentBranch.name}</p>
                    </Tooltip>
                  ) : (
                    <p>{localization.Choosebranch[currentLang]}</p>
                  )}
                </Button> */}
              </div>

              <a href="tel:15672" className="hotline">
                <PhoneOutlined />
                15672
              </a>

              <div className="user-action-holder">
                <ul>
                  {/* {!this.props.location.pathname.includes('/en') ? ( */}
                  {currentLang !== 'en' ? (
                    <li onClick={() => this.changeLang('en')}>
                      <I18Link
                        className="lang-holder"
                        ignoreLocale
                        to={`/en${stripLocale(pathname, locale)}`}
                      >
                        <span>English</span>
                      </I18Link>
                    </li>
                  ) : (
                    <li onClick={() => this.changeLang('ar')}>
                      <I18Link
                        className="lang-holder"
                        ignoreLocale
                        to={`/ar${stripLocale(pathname, locale)}`}
                      >
                        <span>العربية</span>
                      </I18Link>
                    </li>
                  )}
                </ul>

                {currentUser ? (
                  <>
                    <img src={black_user} className="user-icon-holder" alt="" />
                    <div className="user-drop-points">
                      <Dropdown
                        overlayClassName="user-dropdown-wrapper"
                        trigger={['click']}
                        overlay={
                          <Menu onClick={this.handelMenuSelect}>
                            <Menu.Item key="1" icon={<UserOutlined />}>
                              <I18Link to={{ pathname: '/profile' }}>
                                {localization.Profile[currentLang]}
                              </I18Link>
                            </Menu.Item>
                            <Menu.Item key="2" icon={<EnvironmentOutlined />}>
                              <I18Link
                                to={{ pathname: `/profile/2/Addresses` }}
                              >
                                {localization.MyAddresses[currentLang]}
                              </I18Link>
                            </Menu.Item>
                            <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
                              <I18Link to={{ pathname: `/profile/3/orders` }}>
                                {localization.Myordrers[currentLang]}
                              </I18Link>
                            </Menu.Item>
                            <Menu.Item key="8" icon={<FileTextOutlined />}>
                              <I18Link
                                to={{
                                  pathname: `/profile/8/prescription-order`,
                                }}
                              >
                                {localization.prescriptionOrder[currentLang]}
                              </I18Link>
                            </Menu.Item>
                            <Menu.Item key="7" icon={<UploadOutlined />}>
                              <I18Link
                                to={{
                                  pathname: `/profile/7/upload-prescription`,
                                }}
                              >
                                {localization.upload_prescription[currentLang]}
                              </I18Link>
                            </Menu.Item>
                            <Menu.Item key="5" icon={<HeartOutlined />}>
                              <I18Link to={{ pathname: `/profile/5/Favorite` }}>
                                {localization.Favoritelist[currentLang]}
                              </I18Link>
                            </Menu.Item>
                            <Menu.Item
                              key="6"
                              icon={<ImportOutlined />}
                              style={{ color: '#fe5252' }}
                            >
                              {localization.Logout[currentLang]}
                            </Menu.Item>
                          </Menu>
                        }
                        className="login-dropdown"
                      >
                        <Button type="text">
                          {this.props.currentUser?.first_name || 'user'}
                          <CaretDownOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                  </>
                ) : (
                  <I18Link to={{ pathname: '/login' }}>
                    <img className="user-icon-holder" src={black_user} alt="" />
                    <span className="login-link">
                      {localization.Login[currentLang]}
                    </span>
                  </I18Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="main-header">
          <BranchModal
            fetchHomeDetails={this.props.fetchHomeDetails}
            closeModal={() => this.setState({ branchModalVisible: false })}
            branchModalVisible={branchModalVisible}
          />

          {MoreIpad && (
            <div className="category-menu-dropdown">
              <Menu
                triggerSubMenuAction="click"
                className="cat-menu-overlay-holder"
                mode="vertical"
              >
                {categoriesList?.map(item => {
                  return (
                    <SubMenu
                      key={`L1v${item.id}`}
                      className={
                        item?.children?.length === 0 ? 'submen--no-arrow' : ''
                      }
                      title={
                        <span
                          onClick={() => {
                            this.props.history.push(
                              `/${this.props.currentLang}/category/1/${item.id}/${item.name}`
                            );
                            this.props.history.replace(
                              `/${this.props.currentLang}/category/1/${item.id}/${item.name}`
                            );
                            this.toggleCategoriesWithClass();
                            this.setState({ categoryVisible: false });
                          }}
                        >
                          {item.name}
                        </span>
                      }
                      popupClassName={`category-menu-lvl-1 ${
                        !categoryVisible && 'cat-menu-hidden'
                      }`}
                    >
                      {item.children.map(lvl2 => (
                        <SubMenu
                          key={`L2v${lvl2.id}`}
                          title={
                            <span
                              onClick={() => {
                                this.props.history.push(
                                  `/${this.props.currentLang}/category/2/${lvl2.id}/${lvl2.name}`
                                );
                                this.props.history.replace(
                                  `/${this.props.currentLang}/category/2/${lvl2.id}/${lvl2.name}`
                                );
                                this.toggleCategoriesWithClass();
                                this.setState({
                                  categoryVisible: false,
                                });
                              }}
                            >
                              {lvl2.name}
                            </span>
                          }
                          className={`${
                            lvl2.children.length === 0 && 'menu-no-icon'
                          }`}
                          popupClassName={`category-menu-lvl-2 ${
                            !categoryVisible && 'cat-menu-hidden'
                          }`}
                        >
                          {lvl2.children.map(lvl3 => (
                            <Menu.Item
                              key={`L3v${lvl3.id}`}
                              onClick={() => {
                                this.props.history.push(
                                  `/${this.props.currentLang}/category/3/${
                                    lvl3.id
                                  }/${lvl3.name
                                    ?.replace('/', '')
                                    .replace('%', '')}`
                                );
                                this.props.history.replace(
                                  `/${this.props.currentLang}/category/3/${
                                    lvl3.id
                                  }/${lvl3.name
                                    ?.replace('/', '')
                                    .replace('%', '')}`
                                );
                                this.toggleCategoriesWithClass();
                                this.setState({
                                  categoryVisible: false,
                                });
                              }}
                            >
                              {lvl3.name}
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      ))}
                    </SubMenu>
                  );
                })}
              </Menu>
            </div>
          )}
          <div className="container">
            <div className="main-header-content">
              <div className="main-header-top">
                <I18Link to={{ pathname: '/' }} className="khier-logo">
                  <img src={logoUpd} alt="logo" />
                </I18Link>
                <AlignLeftOutlined
                  onClick={this.handelOpenMenu}
                  className="bars-menu-icon"
                />
                <CategoriesMenu loading={categoryLoading} />
                <div className="search-input-holder">
                  <AutoComplete onSearch={this.onSearchInput}>
                    <Input
                      size="large"
                      placeholder={localization.Search[currentLang]}
                      onPressEnter={this.onSearch}
                      suffix={
                        <SearchOutlined
                          onClick={e => this.onSearch(e, this.state.searchVal)}
                        />
                      }
                    />
                  </AutoComplete>
                </div>
                <div className="cart-login">
                  <Button
                    type="link"
                    icon={<img src={favIcon} alt="" width={28} />}
                    onClick={() =>
                      this.props.history.push(
                        `/${currentLang}/profile/5/Favorite`
                      )
                    }
                  />
                  <div className="cart-holder">
                    <I18Link to={{ pathname: '/cart' }}>
                      <Badge count={this.props.cartItems.length} size="large">
                        <img src={black_cart} alt="" />
                      </Badge>
                    </I18Link>
                  </div>
                </div>
              </div>

              <Drawer
                title={localization.Category[currentLang]}
                placement={currentLang === 'en' ? 'left' : 'right'}
                // closable={false}
                onClose={() => {
                  console.log('clicked!!!', drawerVisible);
                  this.setState({ drawerVisible: false });
                }}
                open={drawerVisible}
                // className="drawer-main-header-bottom"
              >
                <Menu
                  // className="cat-res-menu-holder"
                  triggerSubMenuAction="click"
                  mode="inline"
                  // direction={currentLang === 'ar' ? 'rtl' : 'ltr'}
                  direction={'rtl'}
                >
                  <Menu.Item>
                    <I18Link to={{ pathname: '/' }}>
                      {localization.home[currentLang]}
                    </I18Link>
                  </Menu.Item>

                  <Menu.SubMenu title={localization.Categories[currentLang]}>
                    {categoriesList?.map(item => {
                      if (item?.name === 'Medication') return null;
                      return (
                        <SubMenu
                          key={`L1v${item.id}`}
                          // className={
                          //   item?.children?.length === 0 ? 'submen--no-arrow' : ''
                          // }
                          title={
                            <span
                              onClick={() => {
                                this.props.history.push(
                                  `/${this.props.currentLang}/category/1/${item.id}/${item.name}`
                                );
                                // this.props.history.replace(
                                //   `/${this.props.currentLang}/category/1/${item.id}/${item.name}`
                                // );
                                this.setState({ categoryVisible: false });
                              }}
                            >
                              {item.name}
                            </span>
                          }
                          popupClassName={`category-menu-lvl-1 ${
                            !categoryVisible && 'cat-menu-hidden'
                          }`}
                        >
                          {item.children.map(lvl2 => (
                            <SubMenu
                              key={`L2v${lvl2.id}`}
                              title={
                                <span
                                  onClick={() => {
                                    this.props.history.push(
                                      `/${this.props.currentLang}/category/2/${lvl2.id}/${lvl2.name}`
                                    );
                                    // this.props.history.replace(
                                    //   `/${this.props.currentLang}/category/2/${lvl2.id}/${lvl2.name}`
                                    // );
                                    this.setState({ categoryVisible: false });
                                  }}
                                >
                                  {lvl2.name}
                                </span>
                              }
                              // className={`${
                              //   lvl2.children.length === 0 && 'menu-no-icon'
                              // }`}
                              // popupClassName={`category-menu-lvl-2 ${
                              //   !categoryVisible && 'cat-menu-hidden'
                              // }`}
                            >
                              {lvl2.children.map(lvl3 => (
                                <Menu.Item
                                  key={`L3v${lvl3.id}`}
                                  onClick={() => {
                                    this.props.history.push(
                                      `/${this.props.currentLang}/category/3/${
                                        lvl3.id
                                      }/${lvl3.name
                                        ?.replace('/', '')
                                        .replace('%', '')}`
                                    );
                                    // this.props.history.replace(
                                    //   `/${this.props.currentLang}/category/3/${
                                    //     lvl3.id
                                    //   }/${lvl3.name
                                    //     ?.replace('/', '')
                                    //     .replace('%', '')}`
                                    // );
                                    this.setState({ categoryVisible: false });
                                  }}
                                >
                                  {lvl3.name}
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          ))}
                        </SubMenu>
                      );
                    })}
                  </Menu.SubMenu>
                  <Menu.Item>
                    <I18Link to={{ pathname: '/deals' }}>
                      {localization.Deals[currentLang]}
                    </I18Link>
                  </Menu.Item>

                  <Menu.Item>
                    <I18Link to={{ pathname: '/about' }}>
                      {localization.About[currentLang]}
                    </I18Link>
                  </Menu.Item>
                  {currentUser ? (
                    <Menu.Item
                      onClick={this.handelLogOut}
                      className="log-out-holder"
                    >
                      <a>{localization.Logout[currentLang]}</a>
                      <LogoutOutlined />
                    </Menu.Item>
                  ) : (
                    <Menu.Item>
                      <I18Link to={{ pathname: '/login' }}>
                        {localization.Login[currentLang]}
                      </I18Link>
                    </Menu.Item>
                  )}
                </Menu>
                {/* <ul>
                  <li>
                    <I18Link to={{ pathname: '/' }}>
                      {localization.home[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <a>{localization.Categories[currentLang]}</a>
                  </li>
                  <li>
                    <I18Link to={{ pathname: '/deals' }}>
                      {localization.Deals[currentLang]}
                    </I18Link>
                  </li>

                  <li>
                    <I18Link to={{ pathname: '/about' }}>
                      {localization.About[currentLang]}
                    </I18Link>
                  </li>
                  {currentUser ? (
                    <li onClick={this.handelLogOut} className="log-out-holder">
                      <a>{localization.Logout[currentLang]}</a>
                      <LogoutOutlined />
                    </li>
                  ) : (
                    <li>
                      <I18Link to={{ pathname: '/login' }}>
                        {localization.Login[currentLang]}
                      </I18Link>
                    </li>
                  )}
                </ul> */}
              </Drawer>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const HeaderWithRouter = withRouter(Header);
const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  currentLang: state.user.currentLang,
  // currentAddress : state.user.currentAddress,
  currentBranch: state.user.currentBranch,
  cartItems: state.cart.cartItems,
  categories: state.user.categories,
  // setCategories
});
export default connect(mapStateToProps)(HeaderWithRouter);
